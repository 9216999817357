import { createSlice } from '@reduxjs/toolkit'

export const moneyMgmtAcctTypes = createSlice({
    name: 'moneyMgmtAcctTypes',
    initialState: [],
    reducers: {
      saveMoneyMgmtAcctTypes: (state, action) => {
        state = action.payload;
        return state;
      },
    },
  })
  
  // Action creators are generated for each case reducer function
  export const { saveMoneyMgmtAcctTypes } = moneyMgmtAcctTypes.actions
  
  export default moneyMgmtAcctTypes.reducer