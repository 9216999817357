import React, { useEffect, useState } from 'react'
import MoneyManagementHome from './Home/money-management-home'
import NoPageFound from '../../NoPageFound/nopagefound'
import { Route, Routes } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux';
import { saveMenus } from '../../store/reducers/menus-slice';
import { execute_GET_API, responseAuthorisation } from '../../api/api-util';
import Accounts from './Account/accounts';

export default function MoneyManagementComponent() {
    const [loading, setLoading] = useState(true);
    const dispatch = useDispatch();
    const pageMapper = {
        "accounts": <Accounts />,
    };
    const menusarr = useSelector((state) => state.menus);

    useEffect(() => {
        execute_GET_API("/money_management/meta", null)
            .then(response => response.json())
            .then(result => {
                if (responseAuthorisation(result)) {
                    if (result.iserror) {
                        alert(result.errormsg);
                        dispatch(saveMenus([]));
                    } else {
                        const data = result.data;
                        const menus = data.menu;
                        dispatch(saveMenus(menus));
                    }
                    setLoading(false);
                }
            })
            .catch(error => {
                alert("Error Occurred in Meta");
                console.log(error);
                dispatch(saveMenus([]));
                setLoading(false);
            });
    }, []);

    return (
        <div>
            {loading ?
                <div id='application-loading-block'>
                    <div className='h4'>Loading Money Management Application...</div>
                    <div className="progress" role="progressbar" aria-label="Animated striped example" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100">
                        <div className="progress-bar progress-bar-striped progress-bar-animated bg-primary" style={{ width: '100%' }}></div>
                    </div>
                </div>
                :
                <Routes>
                    <Route exact pathcc="/" element={<MoneyManagementHome />} />
                    <Route exact path="" element={<MoneyManagementHome />} />
                    <Route exact path="home" element={<MoneyManagementHome />} />
                    {
                        menusarr.map((block, index) => {
                            const menulist = block.menus;
                            return menulist.map((menu, index) => {
                                const key = menu.key;
                                if (pageMapper[key]) {
                                    return (<Route exact path={key} element={pageMapper[key]} />)
                                }
                            })
                        })
                    }
                    <Route exact path="*" element={<NoPageFound />} />
                </Routes>
            }

        </div>
    )
}
