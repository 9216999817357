import React, { useEffect, useState } from 'react';
import * as CommonUtil from '../../Util/common-util.js';
import InvestorDropdownComponent from '../Util/investor-dropdown-component';
import { execute_POST_API, execute_PUT_API, responseAuthorisation } from '../../../api/api-util';

export default function AddAccount(props) {
    const [customerData, setCustomerData] = useState(props.customerData);
    const [dateTime, setDateTime] = useState("");
    const [accountType, setAccountType] = useState("Weekly");
    const [amount, setAmount] = useState("");
    const [roi, setRoi] = useState(1);
    const [principle, setPrinciple] = useState(0);
    const [interest, setInterest] = useState(0);
    const [investor, setInvestor] = useState("0");
    const [isProcessing, setIsProcessing] = useState(false);

    const calculateAmount = () => {
        if (accountType === "Weekly") {
            setRoi(1);
            if (amount !== "") {
                const interest = (amount * 0.1);
                const principle = (amount - interest);
                setPrinciple(principle);
                setInterest(interest);
            }
        } else {
            setRoi(3);
            if (amount !== "") {
                const interest = (amount * 0.15);
                const principle = (amount - interest);
                setPrinciple(principle);
                setInterest(interest);
            }
        }
    }

    useEffect(() => {
        calculateAmount();
    }, [accountType, amount]);

    const addAccount = (event) => {
        event.preventDefault();
        if (dateTime === "") {
            alert("Please select 'Date - Time'");
            return;
        }
        if (investor === "0") {
            alert("Please choose 'Investor'");
            return;
        }
        if (amount === "" || amount === "0" || amount === 0) {
            alert("Please enter 'Amount' greater than 0");
            return;
        }
        const customername = (customerData.first_name + (customerData.last_name !== "" ? " " + customerData.last_name : ""));
        const params = {
            customer: customerData.id,
            customername,
            dateTime,
            investor,
            accountType,
            amount,
            roi,
            principle,
            interest
        };
        if (props.editType === "add") {
            setIsProcessing(true);
            execute_POST_API("/finance/account/accounts", null, params)
                .then(response => response.json())
                .then(result => {
                    if (responseAuthorisation(result)) {
                        if (result.iserror) {
                            alert(result.errormsg);
                        } else {
                            const data = result.data;
                            if (data.status) {
                                clearForm();
                                props.closePopUp();
                            }
                            alert(data.msg);
                        }
                    }
                    setIsProcessing(false);
                })
                .catch(error => {
                    alert("Error Occurred");
                    console.log(error);
                    setIsProcessing(false);
                });
        } else {
            params.id = props.recordToEdit.id;
            setIsProcessing(true);
            execute_PUT_API("/finance/account/accounts?action=update", null, params)
                .then(response => response.json())
                .then(result => {
                    if (responseAuthorisation(result)) {
                        if (result.iserror) {
                            alert(result.errormsg);
                        } else {
                            const data = result.data;
                            if (data.status) {
                                clearForm();
                                props.closePopUp();
                            }
                            alert(data.msg);
                        }
                    }
                    setIsProcessing(false);
                })
                .catch(error => {
                    alert("Error Occurred");
                    console.log(error);
                    setIsProcessing(false);
                });
        }
    }

    const clearForm = () => {
        setDateTime("");
        setInvestor("0");
        setAccountType("Weekly");
        setAmount(0);
    }

    useEffect(() => {
        setCustomerData(props.customerData)
        if (props.editType === "add") {
            clearForm();
            const currentDateTime = CommonUtil.getCurrentTimeStamp();
            setDateTime(currentDateTime);
        } else {
            const record = props.recordToEdit;
            setDateTime(record.date_time);
            setAccountType(record.type);
            setAmount(record.amount);
            setInvestor(props.investor);
        }
    }, [props]);

    return (
        <div>
            <div className='row h4 border border-3 p-2 border-warning bg-info'>
                <div className='col-6 text-center border-end'>
                    <label className='form-label'>Principle</label>
                    <div className='text-danger' style={{ fontWeight: '700' }}>{principle}</div>
                </div>
                <div className='col-6 text-center'>
                    <label className='form-label'>Interest</label>
                    <div className='text-danger' style={{ fontWeight: '700' }}>{interest}</div>
                </div>
            </div><hr />
            <form className='form' onSubmit={event => addAccount(event)}>
                <div className='row mb-3'>
                    <div className='col-12'>
                        <label className='form-label'><span className='text-danger'>* </span>Date - Time</label>
                        <input type="datetime-local" class="form-control" value={dateTime} onChange={event => setDateTime(event.target.value)} required />
                    </div>
                </div>
                <div className='row mb-3'>
                    <div className='col-12'>
                        <label className='form-label'><span className='text-danger'>* </span>Investor</label>
                        <InvestorDropdownComponent key={"add_account"} investor={investor} setInvestor={setInvestor} useLocalStorage={props.editType === "add" ? true : false}/>
                    </div>
                </div>
                <div className='row mb-3'>
                    <div className='col-12'>
                        <label className='form-label'><span className='text-danger'>* </span>Type</label>
                        <select className='form-select' value={accountType} onChange={event => setAccountType(event.target.value)} required>
                            <option value={"Weekly"}>Weekly</option>
                            <option value={"Monthly"}>Monthly</option>
                        </select>
                    </div>
                </div>
                <div className='row mb-3'>
                    <div className='col-12'>
                        <label className='form-label'><span className='text-danger'>* </span>Amount</label>
                        <input type="number" class="form-control" value={amount} onChange={event => setAmount(event.target.value)} required />
                    </div>
                </div>
                <div className='row mt-4'>
                    <div className='col-6'>
                        <button type='submit' className='btn btn-warning fullsizedbutton' disabled={isProcessing}>
                            {isProcessing ?
                                <div className="spinner-border spinner-border-sm" role="status">
                                    <span className="visually-hidden">Loading...</span>
                                </div>
                                : ""
                            }&nbsp;
                            {props.editType === "add" ? "Add" : "Update"}
                        </button>
                    </div>
                    <div className='col-6'>
                        <button type='reset' className='btn btn-secondary fullsizedbutton' onClick={clearForm} disabled={isProcessing}>Clear</button>
                    </div>
                </div>

                {/*<div className='row mb-3'>
                    <div className='col-12'>
                        <label className='form-label'><span className='text-danger'>* </span>Amount</label>
                        <div className="dropdown">
                            <div className="" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                <span style={{ color: 'darkslategray', position: 'absolute', top: '7px', right: '13px', cursor: 'default' }}>
                                    {searchValue === "" ? <FaAngleDown /> : <FiX onClick={() => { setSearchValue("") }} />}
                                </span>
                                <input type="text" className='form-control' value={searchValue} readOnly />
                            </div>
                            <div className='dropdown-menu' style={{ width: '100%', backgroundColor: 'seashell'}}>
                                <div>
                                    <span style={{ zIndex: '0', position: 'absolute', top: '14px', left: '15px' }}>
                                        <FiSearch />
                                    </span>
                                    <input type="text" className='form-control' style={{ paddingLeft: '30px', marginLeft: '7px', width: '97%' }} value={searchKey} onChange={event => setSearchKey(event.target.value)}/>
                                </div>
                                <hr />
                                <div className='dropdown-item' onClick={() => {setSearchKey(""); setSearchValue("Data 1")}}>Data 1</div>
                                <div className='dropdown-item' onClick={() => {setSearchKey(""); setSearchValue("Data 2")}}>Data 2</div>
                            </div>
                        </div>
                    </div>
                        </div>*/}
            </form>
        </div>
    )
}
