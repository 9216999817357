import React from 'react'
import AddAccount from './add-account'

export default function Accounts() {
  return (
    <div className='mx-auto' style={{width: '95%'}}>
      <div className='row'>
        <div className='col-6 col-md-5 col-lg-3 mt-3'>
            <AddAccount/>
        </div>
        <div className='col-6 col-md-7 col-lg-8 offset-lg-1ß mx-auto mt-3'>
            <table className='table table-responsive'>
                <thead>
                    <tr>
                        <th></th>
                        <th className='text-end'>#</th>
                        <th>Account</th>
                        <th>Type</th>
                        <th className='text-end'>Balance</th>
                    </tr>
                </thead>
            </table>
        </div>
      </div>
      <div className='row mt-3'>
        
      </div>
    </div>
  )
}
