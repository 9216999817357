import React, { useEffect, useState } from 'react';
import $ from 'jquery';
import './css/account-details.css';
import Entry from './entry';
import { execute_GET_API, responseAuthorisation } from '../../../api/api-util';
import { useParams } from 'react-router-dom';
import TableLoadingComponent from '../Util/table-loading-component';
import TableNoDataComponent from '../Util/table-nodata-component';
import { VscEllipsis } from 'react-icons/vsc';
import { convertTypicalDateTimeToHumanReadable } from '../../Util/common-util';
import DeleteEntry from './delete-entry';

export default function AccountDetails() {
    const url_params = useParams();
    const [editType, setEditType] = useState("add");
    const [recordToEdit, setRecordToEdit] = useState({});
    const [loading, setLoading] = useState(false);
    const [entries, setEntries] = useState([]);
    const [customer, setCustomer] = useState({});
    const [account, setAccount] = useState({});
    const [investor, setInvestor] = useState({});
    const [customerName, setCustomerName] = useState("");

    const fetchAccountDetails = () => {
        const params = "account=" + url_params.accountid;
        const url = "/finance/account/account_details?" + params;
        setLoading(true);
        execute_GET_API(url, null)
            .then(response => response.json())
            .then(result => {
                if (responseAuthorisation(result)) {
                    if (result.iserror) {
                        alert(result.errormsg);
                    } else {
                        const data = result.data;
                        setEntries(data.entries);
                        setCustomer(data.customer);
                        setCustomerName(data.customer.first_name ? (data.customer.first_name + " " + data.customer.last_name) : "");
                        setAccount(data.account);
                        setInvestor(data.investor);
                    }
                }
                setLoading(false);
            })
            .catch(error => {
                alert("Error Occurred");
                console.log(error);
                setLoading(false);
            });
    }

    const editEntryPopUpData = (record) => {
        setEditType("update");
        setRecordToEdit(record);
    }

    const addEntryPopUpData = () => {
        setEditType("add");
        setRecordToEdit({});
    }

    const deleteEntryPopUpData = (record) => {
        setEditType("delete");
        setRecordToEdit(record);
    }

    const closePopUp = () => {
        $("#addEntryPopUpCloseBt").trigger("click");
        fetchAccountDetails();
    }

    useEffect(() => {
        fetchAccountDetails();
    }, []);

    useEffect(() => {
        if('balance' in account && account.balance === 0){
            $("#addNewEntryBt").css("display", "none");
        } else {
            $("#addNewEntryBt").css("display", "");
        }
    }, [account]);

    return (
        <div>
            <div className='row mx-auto' style={{ width: '95%'}}>
                <div className='col-lg-3 mt-3' id='info-block'>
                    <div className='row'>
                        <div className='col-12'>
                            <button className='btn btn-warning fullsizedbutton' data-bs-toggle="modal" data-bs-target="#addEntryPopUp" disabled={loading} onClick={addEntryPopUpData} id="addNewEntryBt">
                                Add New Entry 
                            </button>
                            <div class="modal fade" id="addEntryPopUp" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="addEntryPopUpLabel" aria-hidden="true">
                                <div class="modal-dialog">
                                    <div class="modal-content">
                                        <div class="modal-header app-bg-color">
                                            <h1 class="modal-title fs-5" id="addEntryPopUpLabel">{editType === "add" ? "Add Entry" : editType === "update" ? "Update Entry" : "Delete Entry"}</h1>
                                            <button type="button" id='addEntryPopUpCloseBt' class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                        </div>
                                        <div class="modal-body app-bg-color">
                                            {editType === "delete" ?
                                                <DeleteEntry recordToDelete={recordToEdit} closePopUp={closePopUp} />
                                                :
                                                <Entry editType={editType} customer={customer} account={account} investor={investor} recordToEdit={recordToEdit} closePopUp={closePopUp} />
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='mt-4' id="account-meta">
                        <div className='row'>
                            <div className='col-12'>
                                <label className='form-label'>Investor Name</label>
                                <input type='text' className='form-control text-center' value={investor.first_name ? (investor.first_name + " " + investor.last_name) : ""} readOnly />
                            </div>
                        </div>
                        <div className='row mt-3'>
                            <div className='col-12'>
                                <label className='form-label'>Customer Name</label>
                                <input type='text' className='form-control text-center' value={customerName} readOnly />
                            </div>
                        </div>
                        <div className='row mt-3'>
                            <div className='col-12'>
                                <label className='form-label'>Account Date</label>
                                <input type='text' className='form-control text-center' value={account.date_time ? convertTypicalDateTimeToHumanReadable(account.date_time) : ""} readOnly />
                            </div>
                        </div>
                        <div className='row mt-3'>
                            <div className='col-12'>
                                <label className='form-label'>Amount</label>
                                <input type='text' className='form-control text-center' value={account.amount ? account.amount : ""} readOnly />
                            </div>
                        </div>
                        <div className='row mt-3'>
                            <div className='col-12'>
                                <label className='form-label'>Type</label>
                                <input type='text' className='form-control text-center' value={account.type ? account.type : ""} readOnly />
                            </div>
                        </div>
                        <div className='row mt-3'>
                            <div className='col-12'>
                                <label className='form-label'>Status</label>
                                <input type='text' className='form-control text-center' value={account.status ? account.status : ""} readOnly />
                            </div>
                        </div>
                        <div className='row mt-3'>
                            <div className='col-12'>
                                <label className='form-label'>Balance</label>
                                <input type='text' className='form-control text-center' value={'balance' in account ? account.balance : ""} readOnly />
                            </div>
                        </div>
                    </div>
                </div>
                <div className='col-lg-9 mt-4' style={{overflow: 'auto'}}>
                    <table className='table table-striped table-responsive border-warning table-warning'>
                        <thead>
                            <tr>
                                <th></th>
                                <th className='text-end'>#</th>
                                <th>Date</th>
                                <th className='text-end'>Amount</th>
                                <th className='text-end'>Balance</th>
                            </tr>
                        </thead>
                        {loading ?
                            <TableLoadingComponent colspan={5} />
                            :
                            entries.length === 0 ?
                                <TableNoDataComponent colspan={5} />
                                :
                                <tbody>
                                    {entries.map((entry, index) => {
                                        return (
                                            <tr style={{ textWrap: 'nowrap' }}>
                                                <td className='text-center'>
                                                    <VscEllipsis type="button" data-bs-toggle="dropdown" aria-expanded="false" />
                                                    <ul className="dropdown-menu" style={{ cursor: 'pointer' }}>
                                                        <li><div className="dropdown-item" data-bs-toggle="modal" data-bs-target="#addEntryPopUp" onClick={() => editEntryPopUpData(entry)}>Edit</div></li>
                                                        <li><div className="dropdown-item" data-bs-toggle="modal" data-bs-target="#addEntryPopUp" onClick={() => deleteEntryPopUpData(entry)}>Delete</div></li>
                                                    </ul>
                                                </td>
                                                <td className='text-end'>{index + 1}</td>
                                                <td>{entry.date + " " + entry.time}</td>
                                                <td className='text-end'>{entry.credit}</td>
                                                <td className='text-end'>{entry.balance}</td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                        }
                    </table>
                </div>
            </div>
            <div className='mx-auto' style={{ width: '95%' }}>

            </div>

        </div>
    )
}
