import React, { useEffect, useState } from 'react';
import './css/finance-component.css';
import { Route, Routes } from 'react-router-dom';
import FinanceHome from './Home/finance-home';
import FinanceDashboard from './Dashboard/finance-dashboard';
import ViewCustomers from './Customer/view-customers';
import ViewAccounts from './Account/view-accounts';
import NoPageFound from '../../NoPageFound/nopagefound';
import { execute_GET_API, responseAuthorisation } from '../../api/api-util';
import { useDispatch, useSelector } from 'react-redux';
import { saveMenus } from '../../store/reducers/menus-slice';
import { saveFinanceInvestorsData } from '../../store/reducers/finance/investors-slice';
import TransactionSummary from './Management/transaction-summary';
import { saveCustomersMeta } from '../../store/reducers/finance/customers-meta-slice';
import AccountDetails from './Account/account-details';
import AccountsView from './Account/accounts-view';
import MoneyDenominator from '../Util/money-denominator';

export default function FinanceComponent() {
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  const pageMapper = {
    "view-customers": <ViewCustomers />,
    "accounts-view": <AccountsView />,
    "transaction-summary": <TransactionSummary />,
    "dashboard": <FinanceDashboard/>,
    "money-denominator": <MoneyDenominator />
  };
  const menusarr = useSelector((state) => state.menus);

  useEffect(() => {
    execute_GET_API("/finance/meta", null)
      .then(response => response.json())
      .then(result => {
        if (responseAuthorisation(result)) {
          if (result.iserror) {
            alert(result.errormsg);
          } else {

            const data = result.data;
            const menus = data.menu;
            const investors = data.investors;
            const customersMeta = data.customers_meta;
            dispatch(saveMenus(menus));
            dispatch(saveFinanceInvestorsData(investors));
            dispatch(saveCustomersMeta(customersMeta));
          }
          setLoading(false);
        }
      })
      .catch(error => {
        alert("Error Occurred in Meta");
        console.log(error);
        setLoading(false);
      });
  }, []);

  return (
    <div>
      {loading ?
        <div id='application-loading-block'>
          <div className='h4'>Loading Finance Application...</div>
          <div className="progress" role="progressbar" aria-label="Animated striped example" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100">
            <div className="progress-bar progress-bar-striped progress-bar-animated bg-primary" style={{ width: '100%' }}></div>
          </div>
        </div>
        :
        <Routes>
          <Route exact pathcc="/" element={<FinanceDashboard />} />
          <Route exact path="" element={<FinanceDashboard />} />
          <Route exact path="customer/:customerid/view-accounts" element={<ViewAccounts />} />
          <Route exact path="customer/:customerid/account/:accountid/account-details" element={<AccountDetails />} />
          {

            menusarr.map((block, index) => {
              const menulist = block.menus;
              return menulist.map((menu, index) => {
                const key = menu.key;
                if (pageMapper[key]) {
                  return (<Route exact path={key} element={pageMapper[key]} />)
                }
              })
            })
          }
          <Route exact path="*" element={<NoPageFound />} />
        </Routes>
      }
    </div>
  )
}
