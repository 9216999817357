import React, { useEffect } from 'react';
import './css/applications-home.css';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { saveMenus } from '../store/reducers/menus-slice';

export default function ApplicationsHome() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(saveMenus([]));
  }, []);

  return (
    <div className='ms-3 me-3' style={{ color: 'black' }}>
      <div className='row mt-3'>
        <div className='col-6 col-md-4 col-lg-2'>
          <Link to="finance/dashboard" style={{ textDecoration: 'none', color: 'inherit' }}>
            <div class="card text-center m-1">
              <img src="img/finance-logo.jpg" class="card-img-top" alt="Finance" />
              <div class="card-body">
                <h6 class="card-title">Finance</h6>
                <p class="card-text"><small>Application to manage finance.</small></p>
              </div>
            </div>
          </Link>
        </div>
        <div className='col-6 col-md-4 col-lg-2'>
          <Link to="money-management" style={{ textDecoration: 'none', color: 'inherit' }}>
            <div class="card text-center m-1">
              <img src="img/budget-logo.png" class="card-img-top" alt="Money Management" />
              <div class="card-body">
                <h6 class="card-title">Money Management</h6>
                <p class="card-text"><small>Application to manage money transactions.</small></p>
              </div>
            </div>
          </Link>
        </div>
      </div>
    </div>
  )
}
