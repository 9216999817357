export const getMonthFirstDate = () => {
    const today = new Date();
    const currentYear = today.getFullYear();
    const currentMonth = today.getMonth();
    const newDate = new Date(currentYear, currentMonth, 1);
    return getFullDate(newDate);
}

export const getMonthLastDate = () => {
    const today = new Date();
    const currentYear = today.getFullYear();
    const currentMonth = today.getMonth();
    const newDate = new Date(currentYear, currentMonth + 1, 0);
    return getFullDate(newDate);
}

export const getTodayDate = () => {
    return getFullDate(new Date());
}

export const getCurrentTimeStamp = () => {
    return getFullDateTime(new Date());
}

export const getFullDate = (date) => {
    const currentYear = date.getFullYear();
    const currentMonth = date.getMonth() + 1;
    const currentDate = date.getDate();
    const formattedDate = currentYear + "-" + getTwoDigitNumber(currentMonth) + "-" + getTwoDigitNumber(currentDate);
    return formattedDate;
}

export const getFullDateTime = (date) => {
    const currentYear = date.getFullYear();
    const currentMonth = date.getMonth() + 1;
    const currentDate = date.getDate();
    const currentHour = date.getHours();
    const currentMinutes = date.getMinutes();
    const currentSeconds = date.getSeconds();
    const formattedDate = currentYear + "-" + getTwoDigitNumber(currentMonth) + "-" + getTwoDigitNumber(currentDate) + " " + getTwoDigitNumber(currentHour) + ":" + getTwoDigitNumber(currentMinutes) + ":" + getTwoDigitNumber(currentSeconds);
    return formattedDate;
}

const getTwoDigitNumber = (number) => {
    return ((number < 10 ? "0" : "") + number);
}

export const convertTypicalDateTimeToHumanReadable = (date_time) => {
    const timestamp = new Date(date_time);
    const currentYear = timestamp.getFullYear();
    const currentMonth = timestamp.getMonth() + 1;
    const currentDate = timestamp.getDate();
    let currentHour = timestamp.getHours();
    const ampm = currentHour >= 12 ? 'pm' : 'am';
    currentHour = currentHour % 12;
    currentHour = currentHour ? currentHour : 12; // the hour '0' should be '12'
    const currentMinutes = timestamp.getMinutes();
    const currentSeconds = timestamp.getSeconds();
    const formattedDate = (currentDate < 10 ? "0" : "") + currentDate + "-" + getMonthNameByMonthNumber(currentMonth) + "-" + currentYear + " " + (currentHour < 10 ? "0" : "") + currentHour + ":" + (currentMinutes < 10 ? "0" : "") + currentMinutes + ":" + (currentSeconds < 10 ? "0" : "") + currentSeconds + ampm;
    return formattedDate;
}

const getMonthNameByMonthNumber = (monthNumber) => {
    switch(monthNumber) {
        case 1 :
            return "Jan";
        case 2 :
            return "Feb";
        case 3 :
            return "Mar";
        case 4 :
            return "Apr";
        case 5 :
            return "May";
        case 6 :
            return "Jun";
        case 7 :
            return "Jul";
        case 8 :
            return "Aug";
        case 9 :
            return "Sep";
        case 10 :
            return "Oct";
        case 11 :
            return "Nov";
        case 12 :
            return "Dec";
    }
}