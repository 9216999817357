import React, { useEffect, useState } from 'react';
import './css/account-details.css';
import * as CommonUtil from '../../Util/common-util.js';
import { execute_POST_API, execute_PUT_API, responseAuthorisation } from '../../../api/api-util';

export default function Entry(props) {
    const [editType, setEditType] = useState("add");
    const [dateTime, setDateTime] = useState("");
    const [amount, setAmount] = useState("");
    const [investor, setInvestor] = useState({});
    const [account, setAccount] = useState({});
    const [customer, setCustomer] = useState({});
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        setEditType(props.editType);
        setInvestor(props.investor);
        setCustomer(props.customer);
        setAccount(props.account);
        setLoading(false);
        if (props.editType === "add") {
            clearForm();
            const currentDateTime = CommonUtil.getCurrentTimeStamp();
            setDateTime(currentDateTime);
        } else {
            const record = props.recordToEdit;
            setDateTime(record.date + " " + record.time);
            setAmount(record.credit);
        }
    }, [props])

    const addEntry = (event) => {
        event.preventDefault();
        const description = "Debt Paid By " + (customer.first_name ? (customer.first_name + " " + customer.last_name) : "");
        const params = {
            account: account.id,
            date_time: dateTime,
            amount,
            description,
            investor: investor.id
        }
        //alert(JSON.stringify(params));
        //return;
        setLoading(true);
        if (editType === "add") {
            execute_POST_API("/finance/account/account_details", null, params)
                .then(response => response.json())
                .then(result => {
                    if (responseAuthorisation(result)) {
                        if (result.iserror) {
                            alert(result.errormsg);
                        } else {
                            const data = result.data;
                            if (data.status) {
                                clearForm();
                                props.closePopUp();
                            }
                            alert(data.msg);
                        }
                    }
                    setLoading(false);
                })
                .catch(error => {
                    alert("Error Occurred");
                    console.log(error);
                    setLoading(false);
                });
        } else {
            params.id = props.recordToEdit.id;
            execute_PUT_API("/finance/account/account_details", null, params)
                .then(response => response.json())
                .then(result => {
                    if (responseAuthorisation(result)) {
                        if (result.iserror) {
                            alert(result.errormsg);
                        } else {
                            const data = result.data;
                            if (data.status) {
                                clearForm();
                                props.closePopUp();
                            }
                            alert(data.msg);
                        }
                    }
                    setLoading(false);
                })
                .catch(error => {
                    alert("Error Occurred");
                    console.log(error);
                    setLoading(false);
                });
        }
    }

    const clearForm = () => {
        setDateTime("");
        setAmount("");
    }

    return (
        <div>
            <form className='form' onSubmit={event => addEntry(event)}>
                <div className='row'>
                    <div className='col-12'>
                        <label className='form-label'><span className='text-danger'>* </span>Date - Time</label>
                        <input type="datetime-local" className='form-control' value={dateTime} onChange={event => setDateTime(event.target.value)} required />
                    </div>
                </div>
                <div className='row mt-2'>
                    <div className='col-12'>
                        <label className='form-label'><span className='text-danger'>* </span>Amount</label>
                        <input type="number" className='form-control' value={amount} onChange={event => setAmount(event.target.value)} required />
                    </div>
                </div>
                <div className='row mt-4'>
                    <div className='col-6'>
                        <button type='submit' className='btn btn-warning fullsizedbutton' disabled={loading}>
                            {loading ?
                                <span class="spinner-border spinner-border-sm" aria-hidden="true"></span>
                                :
                                editType === "add" ? "Add" : "Update"}
                        </button>
                    </div>
                    <div className='col-6'>
                        <button type='reset' className='btn btn-secondary fullsizedbutton' disabled={loading} onClick={clearForm}>Clear</button>
                    </div>
                </div>
            </form>
        </div>
    )
}
