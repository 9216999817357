import React, { useEffect, useState } from 'react'
import { execute_GET_API, responseAuthorisation } from '../../api/api-util';
import MultiSelectLookupFieldComponent from './multiselect-lookup-field-component';

export default function RolesDropdownComponent(props) {
    const [selectType, setSelectType] = useState("single");
    const [roles, setRoles] = useState([]);
    const [loop, setLoop] = useState(1);
    const [loading, setLoading] = useState(false);
    const primayKeyField = "id";
    const fieldsToShowInDropdown = ["role_name"];

    useEffect(() => {
        //console.log(props);
        setSelectType(props.selectType);
    }, [props]);

    useEffect(() => {
        const recordsPerCall = 50;
        const from = ((loop - 1) * recordsPerCall);
        const limit = ((loop * recordsPerCall) - 1);
        const url = "/roles";
        const params = "from=" + from + "&limit=" + limit + "&action=all_roles";
        const full_url = url + "?" + params;
        setLoading(true);
        execute_GET_API(full_url, null)
            .then(response => response.json())
            .then(result => {
                if (responseAuthorisation(result)) {
                    if (result.iserror) {
                        alert(result.errormsg);
                    } else {
                        const data = result.data;
                        setRoles(roles.concat(data));
                        if (data.length === recordsPerCall) {
                            setLoop(loop++);
                        }
                    }
                    setLoading(false);
                }
                setLoading(false);
            })
            .catch(error => {
                alert("Error Occurred");
                console.log(error);
                setLoading(false);
            });
    }, [loop]);

    return (
        <div>
            <MultiSelectLookupFieldComponent key={"roles_dropdown"} fields={fieldsToShowInDropdown} primayKeyField={primayKeyField} records={roles} disabled={false} placeHolder={"Choose Roles"} loading={loading} setSelectedKeys={props.setSelectedRolesIds} setSelectedValues={props.setSelectedRoleValues}/>
        </div>
    )
}
