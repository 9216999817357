import { createSlice } from '@reduxjs/toolkit'

export const customersMetaSlice = createSlice({
    name: 'customersMeta',
    initialState: [],
    reducers: {
      saveCustomersMeta: (state, action) => {
        state = action.payload;
        return state;
      },
    },
  })
  
  // Action creators are generated for each case reducer function
  export const { saveCustomersMeta } = customersMetaSlice.actions
  
  export default customersMetaSlice.reducer