import React, { useEffect, useState } from 'react'
import MainComponent from '../MainComponent/main-component';
import LoginPage from './login-page';
import { execute_POST_API, responseAuthorisation } from '../api/api-util';
import { useDispatch } from 'react-redux';
import { saveUserDetailsData } from '../store/reducers/user-details-slice';
import { saveToken } from '../store/reducers/token-slice';
import { saveMenus } from '../store/reducers/menus-slice';
import ValidateUser from './validate-user';
import { useLocation } from 'react-router-dom';
import AuthenticationProcessingComponent from './authentication_processing_component';

export default function Authentication() {
    const dispatch = useDispatch();
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [showLoginPage, setShowLoginPage] = useState(true);
    const [isProcessing, setIsProcessing] = useState(true);
    //const [errorMsgForLogin, setErrorMsgForLogin] = useState("");
    const pathname = window.location.pathname;

    useEffect(() => {
        //return;
        const token = localStorage.getItem("token");
        if (!token) {
            setIsLoggedIn(false);
            setShowLoginPage(true);
            setIsProcessing(false);
        } else {
            execute_POST_API("/authentication/authenticate", null, null)
                .then(response => response.json())
                .then(result => {
                    //console.log(result);
                    if (responseAuthorisation(result)) {
                        //dispatch(saveUserDetailsData(result.user));
                        setIsLoggedIn(true);
                        setShowLoginPage(false);
                        setIsProcessing(false);
                        //alert("Success");
                    }
                    /*if (result.valid) {
                        //console.log(result);
                        dispatch(saveToken(result.token));
                        localStorage.setItem("token", result.token);
                        dispatch(saveUserDetailsData(result.userprofile));
                        dispatch(saveMenus(result.allowedmenus));
                        setIsLoggedIn(true);
                        setShowLoginPage(false);
                    } else {
                        localStorage.removeItem("token");
                        setIsLoggedIn(false);
                        setShowLoginPage(true);
                        setErrorMsgForLogin(result.msg);
                    }*/
                })
                .catch(error => {
                    console.log(error);
                    alert("Error Occurred In Authentication");
                    localStorage.removeItem("token");
                    setIsLoggedIn(false);
                    setShowLoginPage(true);
                    setIsProcessing(false);
                })
        }
    }, [])
    return (
        <div>
            {
                pathname === "/validate-user" ?
                    <ValidateUser />
                    :
                    isProcessing ?
                        <AuthenticationProcessingComponent />
                        :
                        isLoggedIn ?
                            <MainComponent />
                            :
                            showLoginPage ?
                                <LoginPage />
                                :
                                <div>
                                    Authenticating... Please Wait....
                                </div>
            }
        </div>
    )
}
