import React, { useEffect, useState } from 'react';
import { FaAngleDown } from 'react-icons/fa';
import { FiSearch, FiX } from 'react-icons/fi';

export default function LookupFieldComponent(props) {
    const [fields, setFields] = useState([]);
    const [primayKeyField, setPrimaryKeyField] = useState("");
    const [records, setRecords] = useState([]);
    const [filteredRecords, setFilteredRecords] = useState([]);
    const [disabled, setDisabled] = useState(false);
    const [preSelectedKey, setPreSelectedKey] = useState(0);
    const [selectedKey, setSelectedKey] = useState(0);
    const [selectedValue, setSelectedValue] = useState("");
    const [searchValue, setSearchValue] = useState("");
    const [placeHolder, setPlaceHolder] = useState("");
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        //alert("Props Changed");
        setFields(props.fields);
        setPrimaryKeyField(props.primayKeyField);
        setRecords(props.records);
        setFilteredRecords(props.records);
        setDisabled(props.disabled);
        setPlaceHolder(props.placeHolder);
        setLoading(props.loading);
        //setPreSelectedKey(props.preSelectedKey);
    }, [props]);

    useEffect(() => {
        if(searchValue.trim() === ""){
            setFilteredRecords(records);
        } else {
            const filtered_records = records.filter((record) => {
                let found = false;
                fields.forEach(field => {
                    if(record[field].toLowerCase().includes(searchValue.trim().toLowerCase())){
                        found = true;
                    }
                }); 
                return found;
            });
            setFilteredRecords(filtered_records);
        }
    }, [searchValue]);

    useEffect(() => {
        props.setSelectedKey(selectedKey);
        props.setSelectedValue(selectedValue);
    }, [selectedKey, selectedValue])

    return (
        <div className="dropdown">
            <div className="" type="button" data-bs-toggle="dropdown" aria-expanded="false" disabled={disabled}>
                <span style={{ color: 'darkslategray', position: 'absolute', top: '7px', right: '13px', cursor: 'default' }}>
                    {selectedValue === "" ? <FaAngleDown /> : <FiX onClick={() => { setSelectedKey(0); setSelectedValue("") }} />}
                </span>
                <input type="text" className='form-control' value={selectedValue} placeholder={placeHolder} readOnly/>
            </div>
            <div className='dropdown-menu' style={{ width: '100%', backgroundColor: 'seashell' }}>
                <div>
                    <span style={{ zIndex: '0', position: 'absolute', top: '14px', left: '15px' }}>
                        <FiSearch />
                    </span>
                    <input type="text" className='form-control' style={{ paddingLeft: '30px', marginLeft: '7px', width: '97%' }} value={searchValue} onChange={event => setSearchValue(event.target.value)} />
                </div>
                <hr />
                {loading ? 
                    <div className='text-center'><span class="spinner-grow spinner-grow-sm text-primary" aria-hidden="true"></span><em> Loading...</em></div>
                    :
                    filteredRecords.map((record, index) => {
                        const key = record[primayKeyField];
                        let value = "";
                        let first = true;
                        fields.forEach(field => {
                            if(record[field] && record[field] != ""){
                                if (first) {
                                    first = false;
                                } else {
                                    value += " - ";
                                }
                                value += record[field];
                            }
                        });
                        return (
                            <div className='dropdown-item' onClick={() => { setSearchValue(""); setSelectedKey(key); setSelectedValue(value) }}>{value}</div>
                        )
                    })
                }
                {/*<div className='dropdown-item' onClick={() => { setSearchKey(""); setSelectedValue("Data 1") }}>Data 1</div>
                <div className='dropdown-item' onClick={() => { setSearchKey(""); setSelectedValue("Data 2") }}>Data 2</div>*/}
            </div>
        </div>
    )
}
