import React, { useEffect, useState } from 'react';
import './css/delete-customer.css';
import { execute_DELETE_API, responseAuthorisation } from '../../../api/api-util';

export default function DeleteCustomer(props) {
    const [record, setRecord] = useState({});
    const [loading, setLoading] = useState(false);

    const deleteCustomer = () => {
        const params = {
            id: record.id
        }
        execute_DELETE_API("/finance/customer/customer", null, params)
            .then(response => response.json())
            .then(result => {
                if (responseAuthorisation(result)) {
                    if (result.iserror) {
                        alert(result.errormsg);
                    } else {
                        const data = result.data;
                        alert(data.msg);
                    }
                }
                props.closePopUp();
            })
            .catch(error => {
                alert("Error Occurred");
                console.log(error);
                props.closePopUp();
            });
    }

    useEffect(() => {
        setRecord(props.recordToDelete);
    }, [props]);

    return (
        <div>
            <div className='text-center'>
                <span className='text-danger fs-2'><em>Are you Sure To Delete </em><br /><strong>"{record.first_name + " " + record.last_name}"</strong></span>
                <div className='row mt-3 text-center'>
                    <div className='col-12'>
                        <button type="button" className="btn btn-danger fullsizedbutton" onClick={deleteCustomer} disabled={loading}>
                            {loading ? 
                                <span class="spinner-border spinner-border-sm" aria-hidden="true"></span>
                                :
                                "Delete"
                            }
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}
