import store from '../store/store';
import { saveToken, removeToken } from '../store/reducers/token-slice';

const dev_api_base_URL = "http://192.168.0.105:82/mohan_internal/server/api";
//const dev_api_base_URL = "http://localhost:82/mohan_internal/server/api";
const prod_api_base_URL = "/api";

export const getAPIBaseURL = () => {
    return process.env.NODE_ENV === "development" ? dev_api_base_URL : prod_api_base_URL;
}

export const execute_POST_API = (url, headers, body) => {
    const requestURL = getAPIBaseURL() + url;
    if (headers === null) {
        headers = {};
    }
    if (body === null) {
        body = {};
    }
    headers.Authorization = "Bearer " + (localStorage.getItem("token") ? localStorage.getItem("token") : "");
    return fetch(requestURL, {
        method: "POST",
        headers: headers,
        body: JSON.stringify(body)
    });
}

export const execute_PUT_API = (url, headers, body) => {
    const requestURL = getAPIBaseURL() + url;
    if (headers === null) {
        headers = {};
    }
    if (body === null) {
        body = {};
    }
    headers.Authorization = "Bearer " + (localStorage.getItem("token") ? localStorage.getItem("token") : "");
    return fetch(requestURL, {
        method: "PUT",
        headers: headers,
        body: JSON.stringify(body)
    });
}

export const execute_DELETE_API = (url, headers, body) => {
    const requestURL = getAPIBaseURL() + url;
    if (headers === null) {
        headers = {};
    }
    if (body === null) {
        body = {};
    }
    headers.Authorization = "Bearer " + (localStorage.getItem("token") ? localStorage.getItem("token") : "");
    return fetch(requestURL, {
        method: "DELETE",
        headers: headers,
        body: JSON.stringify(body)
    });
}

export const execute_GET_API = (url, headers) => {
    const requestURL = getAPIBaseURL() + url;
    if (headers === null) {
        headers = {};
    }
    headers.Authorization = "Bearer " + (localStorage.getItem("token") ? localStorage.getItem("token") : "");
    return fetch(requestURL, {
        method: "GET",
        headers: headers
    });
}

export const responseAuthorisation = (authResponse) => {
    return responseAuthorisationWithRedirectURL(authResponse, null);
}

export const responseAuthorisationWithRedirectURL = (authResponse, redirectURL) => {
    if (authResponse.valid) {
        store.dispatch(saveToken(authResponse.token));
        localStorage.setItem("token", authResponse.token);
        return true;
    }
    alert(authResponse.msg);
    store.dispatch(removeToken());
    localStorage.removeItem("token");
    localStorage.removeItem("selectedInvestor");
    if(redirectURL !== null){
        window.location = redirectURL;
    } else {
        window.location.reload();
    }
    return false;
}