import { configureStore } from "@reduxjs/toolkit";
import userDetailsReducer from "./reducers/user-details-slice";
import tokenReducer from "./reducers/token-slice";
import menusReducer from "./reducers/menus-slice";
import financeInvestorsReducer from "./reducers/finance/investors-slice";
import customersMetaSlice from "./reducers/finance/customers-meta-slice";
import moneyMgmtAcctTypeSlice from "./reducers/money-management/money-mgmt-acct-type-slice";

export default configureStore({
    reducer: {
        userDetails : userDetailsReducer,
        token : tokenReducer,
        menus : menusReducer,
        financeInvestors : financeInvestorsReducer,
        customersMeta : customersMetaSlice,
        moneyMgmtAcctTypes : moneyMgmtAcctTypeSlice
    },
})