import React, { useEffect, useState } from 'react';
import './css/multiselect-lookup-field.css';
import { FaAngleDown } from 'react-icons/fa';
import { FiSearch, FiX } from 'react-icons/fi';

export default function MultiSelectLookupFieldComponent(props) {
    const [fields, setFields] = useState([]);
    const [primayKeyField, setPrimaryKeyField] = useState("");
    const [records, setRecords] = useState([]);
    const [filteredRecords, setFilteredRecords] = useState([]);
    const [disabled, setDisabled] = useState(false);
    const [preSelectedKey, setPreSelectedKey] = useState(0);
    const [selectedKey, setSelectedKey] = useState([]);
    const [selectedValue, setSelectedValue] = useState([]);
    const [searchValue, setSearchValue] = useState("");
    const [placeHolder, setPlaceHolder] = useState("");
    const [loading, setLoading] = useState(false);

    const updateSelectedKey = (key, value, event) => {
        let selectedKeyObj = [...selectedKey];
        const keyIndex = selectedKeyObj.indexOf(key);
        if(event.target.checked){
            if(keyIndex === -1){
                selectedKeyObj.push(key);
            }
        } else{
            if(keyIndex > -1){
                selectedKeyObj.splice(keyIndex, 1);
            }
        }
        setSelectedKey(selectedKeyObj);
        let recordsArr = [...records];
        const recordsIndex = recordsArr.findIndex(record => record[primayKeyField] === key);
        if(recordsIndex > -1){
            let record = recordsArr[recordsIndex];
            if(event.target.checked){
                record.checked = true;
            } else {
                record.checked = false;
            }
            recordsArr[recordsIndex] = record;
            setRecords(recordsArr);
        }
        const valueIndex = selectedValue.indexOf(value);
        if(event.target.checked){
            if(valueIndex === -1){
                selectedValue.push(value);
            }
        } else {
            if(valueIndex > -1) {
                selectedValue.splice(valueIndex, 1);
            }
        }
        setSelectedValue(selectedValue);
    }

    const deSelectAll = () => {
        setSelectedKey([]);
        setSelectedValue([]);
        let recordsArr = [...records];
        for(let i=0; i<recordsArr.length; i++){
            let record = recordsArr[i];
            record.checked = false;
            recordsArr[i] = record;
        }
        setRecords(recordsArr);
    }

    useEffect(() => {
        //alert("Props Changed");
        //console.log(props);
        setFields(props.fields);
        setPrimaryKeyField(props.primayKeyField);
        setRecords(props.records);
        setFilteredRecords(props.records);
        setDisabled(props.disabled);
        setPlaceHolder(props.placeHolder);
        setLoading(props.loading);
        //setPreSelectedKey(props.preSelectedKey);
    }, [props]);

    useEffect(() => {
        if (searchValue.trim() === "") {
            setFilteredRecords(records);
        } else {
            const filtered_records = records.filter((record) => {
                let found = false;
                fields.forEach(field => {
                    if (record[field].toLowerCase().includes(searchValue.trim().toLowerCase())) {
                        found = true;
                    }
                });
                return found;
            });
            setFilteredRecords(filtered_records);
        }
    }, [searchValue, records]);

    useEffect(() => {
        props.setSelectedKeys(selectedKey);
        props.setSelectedValues(selectedValue);
        //alert("Keys : " + selectedKey.join());
    }, [selectedKey, selectedValue])

    return (
        <div className="dropdown">
            <div className="" type="button" data-bs-toggle="dropdown" data-bs-auto-close="outside" aria-expanded="false" disabled={disabled}>
                <span style={{ color: 'darkslategray', position: 'absolute', top: '7px', right: '13px', cursor: 'default' }}>
                    {selectedValue.length === 0 ? <FaAngleDown /> : <FiX onClick={() => { deSelectAll() }} />}
                </span>
                <input type="text" className='form-control' value={selectedValue.join(", ")} placeholder={placeHolder} readOnly />
            </div>
            <div className='dropdown-menu' style={{ width: '100%', backgroundColor: 'seashell' }}>
                <div>
                    <span style={{ zIndex: '0', position: 'absolute', top: '14px', left: '15px' }}>
                        <FiSearch />
                    </span>
                    <input type="text" className='form-control' style={{ paddingLeft: '30px', marginLeft: '7px', width: '97%' }} value={searchValue} onChange={event => setSearchValue(event.target.value)} />
                </div>
                <hr />
                {loading ?
                    <div className='text-center'><span class="spinner-grow spinner-grow-sm text-primary" aria-hidden="true"></span><em> Loading...</em></div>
                    :
                    filteredRecords.map((record, index) => {
                        const key = record[primayKeyField];
                        let value = "";
                        let first = true;
                        fields.forEach(field => {
                            if (record[field] && record[field] != "") {
                                if (first) {
                                    first = false;
                                } else {
                                    value += " - ";
                                }
                                value += record[field];
                            }
                        });
                        if (preSelectedKey === key) {
                            setSelectedKey(key);
                            setSelectedValue(value);
                        }
                        let checked = false;
                        if(record.hasOwnProperty("checked")){
                            checked = record.checked;
                        }
                        //console.log(value + " : " + checked);
                        return (
                            <div className='dropdown-item'>
                                <input className="form-check-input multiselect-field-chkbox" type="checkbox" value="" id={"multiselect_record" + key} checked={checked} onChange={event => updateSelectedKey(key, value, event)}/>
                                <label className="form-check-label" for={"multiselect_record" + key}>&nbsp;{value}</label>
                            </div>
                        )
                    })
                }
            </div>
        </div>
    )
}
