import React, { useEffect, useState } from 'react';
import $ from 'jquery';
import CustomersDropdownComponent from '../Util/customers-dropdown-component';
import Paging from '../../Util/paging';
import InvestorDropdownComponent from '../Util/investor-dropdown-component';
import { execute_GET_API, responseAuthorisation } from '../../../api/api-util';
import { VscEllipsis } from 'react-icons/vsc';
import { Link } from 'react-router-dom';
import TableLoadingComponent from '../Util/table-loading-component';
import TableNoDataComponent from '../Util/table-nodata-component';
import AddAccount from './add-account';
import DeleteAccount from './delete-account';
import CloseAccount from './close-account';
import ActiveAccount from './active-account';
import Alert from '../../Util/alert';

export default function AccountsView() {
    const [editType, setEditType] = useState("add");
    const [recordToEdit, setRecordToEdit] = useState({});
    const [selectedCustomerValue, setSelectedCustomerValue] = useState("");
    const [selectedCustomerId, setSelectedCustomerId] = useState(0);
    const [loading, setLoading] = useState(false);
    const [displayAccountStatus, setDisplayAccountStatus] = useState("Active");
    const [investor, setInvestor] = useState("0");
    const [currentPage, setCurrentPage] = useState(1);
    const [totalRecords, setTotalRecords] = useState(0);
    const [accounts, setAccounts] = useState([]);
    const recordsPerPage = 10;
    const pagesLimitToDisplay = 7;
    const [customerDetailsLoading, setCustomerDetailsLoading] = useState(false);
    const [customerData, setCustomerData] = useState({});
    const [accountsCount, setAccountsCount] = useState([]);
    const [activeAccountsCount, setActiveAccountsCount] = useState(0);
    const [closedAccountsCount, setClosedAccountsCount] = useState(0);
    const [alertMsg, setAlertMsg] = useState("");
    const [showAlert, setShowAlert] = useState(false);
    const [alertType, setAlertType] = useState("info");

    //Initializing Tooltip
    const tooltipTriggerList = document.querySelectorAll('[data-bs-toggle="tooltip"]')
    const tooltipList = [...tooltipTriggerList].map(tooltipTriggerEl => new window.bootstrap.Tooltip(tooltipTriggerEl))    

    const fetchCustomerDetails = () => {
        const url = "/finance/customer/customer";
        const params = "action=customer_details&customer=" + selectedCustomerId;
        const full_url = url + "?" + params;
        setCustomerDetailsLoading(true);
        execute_GET_API(full_url, null)
            .then(response => response.json())
            .then(result => {
                if (responseAuthorisation(result)) {
                    if (result.iserror) {
                        setShowAlert(true);
                        setAlertMsg(result.errormsg);
                        setAlertType("danger");
                        //alert(result.errormsg);
                    } else {
                        const data = result.data;
                        setCustomerData(data.customer_data);
                        //alert(Object.keys(data.customer_data).length);
                        setAccountsCount(data.accounts_count);
                    }
                }
                setCustomerDetailsLoading(false);
            })
            .catch(error => {
                //alert("Error Occurred");
                setShowAlert(true);
                setAlertMsg("Error Occurred While Fetching Customer Details");
                setAlertType("danger");
                console.log(error);
                setCustomerDetailsLoading(false);
            });
    }

    useEffect(() => {
        if (selectedCustomerId === 0) {
            setCustomerData({});
            setAccountsCount([]);
            setActiveAccountsCount(0);
            setClosedAccountsCount(0);
        } else {
            fetchCustomerDetails();
        }
    }, [selectedCustomerId]);

    const setActiveClosedAccountsCount = () => {
        if (investor !== "0") {
            const index = accountsCount.findIndex((obj) => {
                const investorObj = obj.investor;
                return ("" + investorObj.id) === investor;
            });
            if (index !== -1) {
                const obj = accountsCount[index];
                setActiveAccountsCount(obj.active_accounts_count);
                setClosedAccountsCount(obj.closed_accounts_count);
            } else {
                setActiveAccountsCount(0);
                setClosedAccountsCount(0);
            }
        } else {
            setActiveAccountsCount(0);
            setClosedAccountsCount(0);
        }
    }

    useEffect(() => {
        setActiveClosedAccountsCount();
    }, [accounts]);

    const fetchAccounts = () => {
        if (selectedCustomerId === 0) {
            alert("Please Choose Customer...");
            return;
        }
        if (investor === "0") {
            alert("Please Choose Investor...");
            return;
        }
        const from = ((currentPage - 1) * recordsPerPage);
        const limit = recordsPerPage;
        const params = "customer=" + selectedCustomerId + "&from=" + from + "&limit=" + limit + "&investor=" + investor + "&status=" + displayAccountStatus;
        const url = "/finance/account/accounts?" + params;
        setLoading(true);
        execute_GET_API(url, null)
            .then(response => response.json())
            .then(result => {
                if (responseAuthorisation(result)) {
                    if (result.iserror) {
                        alert(result.errormsg);
                    } else {
                        const data = result.data;
                        setTotalRecords(data.count);
                        setAccounts(data.records);
                    }
                }
                setLoading(false);
            })
            .catch(error => {
                alert("Error Occurred");
                console.log(error);
                setLoading(false);
            });
    }

    const editAccountPopUpData = (record) => {
        setEditType("update");
        setRecordToEdit(record);
    }

    const closeOrActiveAccountPopUpData = (record, $status) => {
        setEditType($status);
        setRecordToEdit(record);
    }

    const addAccountPopUpData = () => {
        setEditType("add");
        setRecordToEdit({});
    }

    const deleteAccountPopUpData = (record) => {
        setEditType("delete");
        setRecordToEdit(record);
    }

    const closeAddAccountPopUp = () => {
        $("#addAccountPopupCloseBt").trigger("click");
        fetchAccounts();
    }

    const closeDeleteAccountPopUp = () => {
        //alert("In");
        $("#deleteAccountPopupCloseBt").trigger("click");
        fetchAccounts();
    }

    useEffect(() => {
        if ((currentPage === 1 && totalRecords !== 0) || currentPage > 1) {
            fetchAccounts();
        }
    }, [currentPage]);

    useEffect(() => {
        setTotalRecords(0);
        setAccounts([]);
        if (selectedCustomerId !== 0 && investor !== "0") {
            fetchAccounts();
        }
    }, [displayAccountStatus]);

    return (
        <div style={{ width: '95%', margin: 'auto' }}>
            <div className='row' style={{ display: (showAlert ? "" : "none") }}>
                <div className='col-lg-4 col-12 mx-auto'>
                    <Alert alertMsg={alertMsg} showAlert={showAlert} alertType={alertType} setShowAlert={setShowAlert} />
                </div>
            </div>
            <div className='row mt-3' style={{ visibility: ((Object.keys(customerData).length > 0) || customerDetailsLoading ? 'visible' : 'hidden') }}>
                <div className='col-lg-5 col-11 mx-auto text-center border' style={{ borderRadius: '2rem' }}>
                    <div className='h3 text-danger mt-1'><strong>{customerData.first_name ? customerData.first_name + " " + customerData.last_name : ""}</strong></div><hr />
                    <div className='h6'>{customerData.phone ? customerData.phone : ""}</div>
                    <div className='h6'>{customerData.email ? customerData.email : ""}</div>
                    <div className='h6'>{customerData.address ? customerData.address : ""}</div>
                    <table className='table'>
                        <thead>
                            <tr className='align-top'>
                                <th className='text-start'>Investor</th>
                                <th className='text-end'>Active</th>
                                <th className='text-end'>Closed</th>
                            </tr>
                        </thead>
                        {customerDetailsLoading ?
                            <TableLoadingComponent colspan={3} />
                            :
                            accountsCount.length === 0 ?
                                <TableNoDataComponent colspan={3} />
                                :
                                <tbody>
                                    {accountsCount.map((obj, index) => {
                                        return (
                                            <tr>
                                                <td className='text-start'>{obj.investor.first_name + " " + obj.investor.last_name}</td>
                                                <td className='text-end'>{obj.active_accounts_count}</td>
                                                <td className='text-end'>{obj.closed_accounts_count}</td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                        }
                    </table>
                </div>
            </div>
            <div className='row'>
                <div className='col-lg-4 mt-3'>
                    <CustomersDropdownComponent setSelectedCustomerId={setSelectedCustomerId} setSelectedCustomerValue={setSelectedCustomerValue} />
                </div>
                <div className='col-lg-4 mt-3'>
                    <InvestorDropdownComponent key={"accounts_view"} investor={investor} setInvestor={setInvestor} useLocalStorage={true} />
                </div>
                <div className='col-lg-4 mt-3'>
                    <button className='btn btn-warning fullsizedbutton' disabled={loading} onClick={fetchAccounts}>
                        {loading ?
                            <span class="spinner-border spinner-border-sm" aria-hidden="true"></span>
                            :
                            "Fetch Accounts"
                        }
                    </button>
                </div>
            </div>
            <div className='row mt-3'>
                <div className='col-lg-8'>
                    <ul className="nav nav-tabs" style={{ cursor: 'pointer' }}>
                        <li className="nav-item">
                            <span className={"nav-link" + (displayAccountStatus === "Active" ? " active" : "")} aria-current="page" onClick={() => setDisplayAccountStatus("Active")}>
                                Active <span className={"badge rounded-pill" + (activeAccountsCount === 0 ? " text-bg-secondary" : " text-bg-primary")}>{activeAccountsCount}</span>
                            </span>
                        </li>
                        <li className="nav-item">
                            <span className={"nav-link" + (displayAccountStatus === "Closed" ? " active" : "")} onClick={() => setDisplayAccountStatus("Closed")}>
                                Closed <span className={"badge rounded-pill" + (closedAccountsCount === 0 ? " text-bg-secondary" : " text-bg-primary")}>{closedAccountsCount}</span>
                            </span>
                        </li>
                    </ul>
                </div>
                <div className='col-lg-4 mt-3'>
                    {selectedCustomerId !== 0 ?
                        <button type="button" className="btn btn-warning fullsizedbutton" data-bs-toggle="modal" data-bs-target="#addAccountPopup" onClick={addAccountPopUpData}>
                            Add New Account
                        </button>
                        :
                        ""
                    }
                    <div className="modal fade" id="addAccountPopup" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="addAccountPopupLabel" aria-hidden="true">
                        <div className="modal-dialog">
                            <div className="modal-content">
                                <div className="modal-header app-bg-color">
                                    <h1 className="modal-title fs-5" id="addAccountPopupLabel">{editType === "add" ? "Add New Account" : "Update Account"}</h1>
                                    <button type="button" id='addAccountPopupCloseBt' className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div className="modal-body app-bg-color">
                                    <AddAccount customerData={customerData} investor={investor} editType={editType} recordToEdit={recordToEdit} closePopUp={closeAddAccountPopUp} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal fade" id="deleteAccountPopup" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="deleteAccountPopupLabel" aria-hidden="true">
                        <div className="modal-dialog">
                            <div className="modal-content">
                                <div className="modal-header app-bg-color">
                                    <h1 className="modal-title fs-5 text-danger text-center" id="deleteAccountPopupLabel" style={{ width: '100%' }}>
                                        {editType === "delete" ?
                                            "Delete Account"
                                            :
                                            editType === "close" ?
                                                "Close Account"
                                                :
                                                "Active Account"
                                        }

                                    </h1>
                                    <button type="button" id='deleteAccountPopupCloseBt' className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div className="modal-body app-bg-color">
                                    {editType === "delete" ?
                                        <DeleteAccount recordToDelete={recordToEdit} closePopUp={closeDeleteAccountPopUp} />
                                        :
                                        editType === "close" ?
                                            <CloseAccount recordToDelete={recordToEdit} closePopUp={closeDeleteAccountPopUp} />
                                            :
                                            <ActiveAccount recordToDelete={recordToEdit} closePopUp={closeDeleteAccountPopUp} />
                                    }

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='row mt-4'>
                <div className='col-12 text-center mx-auto'>
                    <Paging key={"view_accounts"} setCurrentPage={setCurrentPage} currentPage={currentPage} totalRecords={totalRecords} recordsPerPage={recordsPerPage} pagesLimitToDisplay={pagesLimitToDisplay} />
                </div>
            </div>
            <div className='row'>
                <div className='col-12' style={{ overflow: 'auto' }}>
                    <table className='table table-striped table-responsive border-warning table-warning'>
                        <thead>
                            <tr>
                                <th></th>
                                <th className='text-end'>#</th>
                                <th>Date</th>
                                <th>Type</th>
                                <th className='text-end'><span data-bs-toggle="tooltip" data-bs-title={"Principle + Interset"}>Amount</span></th>
                                <th className='text-end'>Paid</th>
                                <th className='text-end'>Balance</th>
                                <th className='text-center'>Status</th>
                            </tr>
                        </thead>
                        {loading ?
                            <TableLoadingComponent colspan={8} />
                            :
                            accounts.length === 0 ?
                                <TableNoDataComponent colspan={8} />
                                :
                                <tbody>
                                    {accounts.map((account, index) => {
                                        const currentIndex = (((currentPage - 1) * recordsPerPage) + (index + 1));
                                        if(account.total_paid === null){
                                            account.total_paid = 0;
                                        }
                                        return (
                                            <tr style={{ textWrap: 'nowrap' }}>
                                                <td className='text-center'>
                                                    <VscEllipsis type="button" data-bs-toggle="dropdown" aria-expanded="false" />
                                                    <ul className="dropdown-menu" style={{ cursor: 'pointer' }}>
                                                        <li><Link to={"/finance/customer/" + selectedCustomerId + "/account/" + account.id + "/account-details"} className="dropdown-item" style={{ textDecoration: 'none', color: 'inherit' }}>Account Details</Link></li>
                                                        <li><div className="dropdown-item" data-bs-toggle="modal" data-bs-target="#deleteAccountPopup" onClick={() => closeOrActiveAccountPopUpData(account, (account.status === "Active" ? "close" : "active"))}>{account.status === "Active" ? "Close Account" : "Active Account"}</div></li>
                                                        <li><div className="dropdown-item" data-bs-toggle="modal" data-bs-target="#addAccountPopup" onClick={() => editAccountPopUpData(account)}>Edit</div></li>
                                                        <li><div className="dropdown-item" data-bs-toggle="modal" data-bs-target="#deleteAccountPopup" onClick={() => deleteAccountPopUpData(account)}>Delete</div></li>
                                                    </ul>
                                                </td>
                                                <td className='text-end'>{currentIndex}</td>
                                                <td><Link to={"/finance/customer/" + selectedCustomerId + "/account/" + account.id + "/account-details"} className="dropdown-item" style={{ textDecoration: 'none', color: 'inherit' }}>{account.date_time}</Link></td>
                                                <td><Link to={"/finance/customer/" + selectedCustomerId + "/account/" + account.id + "/account-details"} className="dropdown-item" style={{ textDecoration: 'none', color: 'inherit' }}>{account.type}</Link></td>
                                                <td className='text-end text-primary'><span data-bs-toggle="tooltip" data-bs-title={account.principle + " + " + account.interest}>{account.amount}</span></td>
                                                <td className='text-end text-success'>{account.total_paid}</td>
                                                <td className='text-end text-danger'>{account.amount - account.total_paid}</td>
                                                <td className={'text-center' + (account.status === "Active" ? ' text-success' : ' text-danger')}>{account.status}</td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                        }
                    </table>
                </div>
            </div>
        </div>
    )
}
