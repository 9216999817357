import React from 'react'

export default function TableNoDataComponent(props) {
    return (
        <tbody>
            <tr>
                <td colSpan={props.colspan} className='text-center'>
                    <em>No Data Found</em>
                </td>
            </tr>
        </tbody>
    )
}
