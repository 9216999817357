import React, { useEffect } from 'react'
import { execute_POST_API, responseAuthorisationWithRedirectURL } from '../api/api-util';
import AuthenticationProcessingComponent from './authentication_processing_component';

export default function ValidateUser() {
    useEffect(() => {
        let paramsMap = {};
        const urlParams = window.location.search;
        const paramString = urlParams.split('?')[1];
        const params_arr = paramString.split('&');
        for (let i = 0; i < params_arr.length; i++) {
            const pair = params_arr[i].split('=');
            const key = pair[0];
            const value = pair[1];
            paramsMap[key] = value;
        }
        if(paramsMap["code"]){
            const state = decodeURIComponent(paramsMap["state"]);
            //alert(state);
            const url = "/authentication/validate_user?code=" + paramsMap["code"];
            execute_POST_API(url, null, null)
                .then(response => response.json())
                .then(result => {
                    console.log(result);
                    if(responseAuthorisationWithRedirectURL(result, state)){
                        if (result.iserror) {
                            alert(result.errormsg);
                            return;
                        }
                        if(paramsMap["state"]){
                            window.location = state;
                        } else {
                            window.location = "/";
                        }
                    }
                })
                .catch(error => {
                    console.log(error);
                    alert("Error Occurred");
                    if(paramsMap["state"]){
                        window.location = state;
                    } else {
                        window.location = "/";
                    }
                });
        }
    }, []);
    return (
        <div>
            <AuthenticationProcessingComponent />
        </div>
    )
}
