import React, { useEffect, useState } from 'react';
import './css/view-customers.css';
import AddCustomer from './add-customer';
import { execute_GET_API, responseAuthorisation } from '../../../api/api-util';
import { VscEllipsis } from "react-icons/vsc";
import DeleteCustomer from './delete-customer';
import $ from 'jquery';
import { Link } from 'react-router-dom';
import InvestorDropdownComponent from '../Util/investor-dropdown-component';

export default function ViewCustomers() {
    const [investor, setInvestor] = useState(0);
    const [editType, setEditType] = useState("add");
    const [loadingRecords, setLoadingRecords] = useState(false);
    const [recordToEdit, setRecordToEdit] = useState({});
    const [totalRecords, setTotalRecords] = useState(0);
    const [records, setRecords] = useState([]);

    const getCustomers = () => {
        const url = "/finance/customer/customer";
        const params = "from=0&limit=100&action=active_customers";
        const full_url = url + "?" + params;
        setLoadingRecords(true);
        execute_GET_API(full_url, null)
            .then(response => response.json())
            .then(result => {
                if (responseAuthorisation(result)) {
                    if (result.iserror) {
                        alert(result.errormsg);
                    } else {
                        const data = result.data;
                        setTotalRecords(data.count);
                        setRecords(data.records);
                    }
                }
                setLoadingRecords(false);
            })
            .catch(error => {
                alert("Error Occurred");
                console.log(error);
                setLoadingRecords(false);
            });
    }

    const editCustomerPopUpData = (record) => {
        setEditType("update");
        setRecordToEdit(record);
    }

    const addCustomerPopUpData = () => {
        setEditType("add");
        setRecordToEdit({});
    }

    const deleteCustomerPopUpData = (record) => {
        setRecordToEdit(record);
    }

    const closeAddCustomerPopUp = () => {
        $("#addCustomerPopupCloseBt").trigger("click");
        getCustomers();
    }

    const closeDeleteCustomerPopUp = () => {
        $("#deleteCustomerPopupCloseBt").trigger("click");
        getCustomers();
    }

    useEffect(() => {
        getCustomers();
    }, [investor])

    return (
        <div className='view-area mx-auto'>
            <div className='row'>
                <div className='col-lg-4 mt-3'>
                    <button type="button" className="btn btn-warning fullsizedbutton" data-bs-toggle="modal" data-bs-target="#addCustomerPopup" onClick={addCustomerPopUpData}>
                        Add New Customer
                    </button>
                    <div className="modal fade" id="addCustomerPopup" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="addCustomerPopupLabel" aria-hidden="true">
                        <div className="modal-dialog">
                            <div className="modal-content">
                                <div className="modal-header app-bg-color">
                                    <h1 className="modal-title fs-5" id="addCustomerPopupLabel">{editType === "add" ? "Add New Customer" : "Update Customer"}</h1>
                                    <button type="button" id='addCustomerPopupCloseBt' className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div className="modal-body app-bg-color">
                                    <AddCustomer editType={editType} recordToEdit={recordToEdit} closePopUp={closeAddCustomerPopUp} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal fade" id="deleteCustomerPopup" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="deleteCustomerPopupLabel" aria-hidden="true">
                        <div className="modal-dialog">
                            <div className="modal-content">
                                <div className="modal-header app-bg-color">
                                    <h1 className="modal-title fs-5 text-danger text-center" id="deleteCustomerPopupLabel" style={{ width: '100%' }}>Delete Customer</h1>
                                    <button type="button" id='deleteCustomerPopupCloseBt' className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div className="modal-body app-bg-color">
                                    <DeleteCustomer recordToDelete={recordToEdit} closePopUp={closeDeleteCustomerPopUp} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/*<div className='col-lg-4 mt-3'>
                    <InvestorDropdownComponent investor={investor} setInvestor={setInvestor} />
                </div>*/}
            </div><hr />
            <div className='row'>
                <div className='col-12'>
                    Total Records : {totalRecords}
                </div>
                <hr />
            </div>
            <div className='row'>
                <div className='col-12' style={{ overflowX: 'scroll' }}>
                    <table className='table table-striped table-responsive border-warning table-warning'>
                        <thead>
                            <tr>
                                <th></th>
                                <th className='text-end'>#</th>
                                <th>Name</th>
                                <th>Phone</th>
                                <th>Email</th>
                                <th>Address</th>
                            </tr>
                        </thead>
                        {loadingRecords ?
                            <tbody className='placeholder-glow'>
                                <tr>
                                    <td></td>
                                    <td colSpan={"5"} className=''><span class="placeholder col-12 bg-secondary"></span></td>
                                </tr>
                                <tr>
                                    <td></td>
                                    <td colSpan={"5"}><span class="placeholder col-12 bg-secondary"></span></td>
                                </tr>
                                <tr>
                                    <td></td>
                                    <td colSpan={"5"}><span class="placeholder col-12 bg-secondary"></span></td>
                                </tr>
                            </tbody>
                            :
                            totalRecords === 0 ?
                                <tbody>
                                    <tr>
                                        <td></td>
                                        <td colSpan={"5"} className='text-center'><em>No Data Found</em></td>
                                    </tr>
                                </tbody>
                                :

                                <tbody>{
                                    records.map((record, index) => {
                                        const customerid = record.id;
                                        return (
                                            <tr>
                                                <td className='text-center'>
                                                    <VscEllipsis type="button" data-bs-toggle="dropdown" aria-expanded="false" />
                                                    <ul className="dropdown-menu">
                                                        {/*<li><Link to={"/finance/customer/" + customerid + "/view-accounts"} className="dropdown-item" style={{ textDecoration: 'none', color: 'inherit' }}>View Accounts</Link></li>*/}
                                                        <li><div className="dropdown-item" data-bs-toggle="modal" data-bs-target="#addCustomerPopup" onClick={() => editCustomerPopUpData(record)}>Edit</div></li>
                                                        <li><div className="dropdown-item" data-bs-toggle="modal" data-bs-target="#deleteCustomerPopup" onClick={() => deleteCustomerPopUpData(record)}>Delete</div></li>
                                                    </ul>
                                                </td>
                                                <td className='text-end'>{(index + 1) + "."}</td>
                                                <td style={{ textWrap: 'nowrap' }}>{record.first_name + " " + record.last_name}</td>
                                                <td>{record.phone}</td>
                                                <td>{record.email}</td>
                                                <td>{record.address}</td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                        }
                    </table>
                </div>
            </div>
        </div>
    )
}
