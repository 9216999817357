import React from 'react'

export default function TableLoadingComponent(props) {
    return (
        <tbody>
            <tr>
                <td colSpan={props.colspan}>
                    <div className="progress" role="progressbar" aria-label="Animated striped example" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100">
                        <div className="progress-bar progress-bar-striped progress-bar-animated" style={{ width: '100%' }}></div>
                    </div>
                </td>
            </tr>
            <tr>
                <td colSpan={props.colspan}>
                    <div className="progress" role="progressbar" aria-label="Animated striped example" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100">
                        <div className="progress-bar progress-bar-striped progress-bar-animated bg-success" style={{ width: '100%' }}></div>
                    </div>
                </td>
            </tr>
            <tr>
                <td colSpan={props.colspan}>
                    <div className="progress" role="progressbar" aria-label="Animated striped example" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100">
                        <div className="progress-bar progress-bar-striped progress-bar-animated bg-danger" style={{ width: '100%' }}></div>
                    </div>
                </td>
            </tr>
        </tbody>
    )
}
