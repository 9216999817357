import React, { useEffect, useState } from 'react';
import './css/delete-account.css';
import { execute_DELETE_API, responseAuthorisation } from '../../../api/api-util';

export default function DeleteAccount(props) {
    const [record, setRecord] = useState(props.recordToDelete);
    const [isProcessing, setIsProcessing] = useState(false);

    const deleteRecord = () => {
        const params = {
            id: record.id
        }
        setIsProcessing(true);
        execute_DELETE_API("/finance/account/accounts", null, params)
            .then(response => response.json())
            .then(result => {
                if (responseAuthorisation(result)) {
                    if (result.iserror) {
                        alert(result.errormsg);
                    } else {
                        const data = result.data;
                        alert(data.msg);
                    }
                }
                props.closePopUp();
                setIsProcessing(false);
            })
            .catch(error => {
                alert("Error Occurred");
                console.log(error);
                props.closePopUp();
                setIsProcessing(false);
            });
    }

    useEffect(() => {
        setRecord(props.recordToDelete);
    }, [props]);

    return (
        <div>
            <div className='text-center'>
                <span className='text-danger fs-2'><em>Are you Sure To Delete The Account </em><br /><strong>"{record.type + " - " + record.amount}<br />{record.date_time}"</strong></span>
                <div className='row mt-3 text-center'>
                    <div className='col-12'>
                        <button type="button" className="btn btn-danger fullsizedbutton" onClick={deleteRecord} disabled={isProcessing}>
                            {isProcessing ?
                                <div className="spinner-border spinner-border-sm" role="status">
                                    <span className="visually-hidden">Loading...</span>
                                </div>
                                : ""
                            }&nbsp;
                            Delete
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}
