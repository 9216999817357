import React, { useEffect } from "react";
import './css/main-component.css';
import Header from "../Header/header";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import ApplicationsHome from "../Applications/applications-home.js";
import NoPageFound from "../NoPageFound/nopagefound";
import { useDispatch, useSelector } from "react-redux";
import FinanceComponent from "../Applications/Finance/finance-component.js";
import { execute_GET_API, responseAuthorisation } from "../api/api-util.js";
import { saveUserDetailsData } from "../store/reducers/user-details-slice.js";
import MoneyManagementComponent from "../Applications/Money-Management/money-management-component.js";

function MainComponent() {
    const dispatch = useDispatch();
    const getMeta = () => {
        execute_GET_API("/meta", null)
            .then(response => response.json())
            .then(result => {
                if (responseAuthorisation(result)) {
                    if (result.iserror) {
                        alert(result.errormsg);
                    } else {
                        const data = result.data;
                        const user_meta = data.user_meta;
                        dispatch(saveUserDetailsData(user_meta));
                    }
                }
            })
            .catch(error => {
                alert("Error Occurred in Meta");
                console.log(error);
            });
    }

    useEffect(() => {
        getMeta();
    }, []);

    return (
        <div id="main-component" className="mx-auto">
            <div>
                <BrowserRouter>
                    <Header />
                    <div className="row" style={{ backgroundColor: '#0088797d', minHeight: '100vh', color: '#ffffff' }}>
                        <div className="col-12">
                            <Routes>
                                <Route exact path="/" element={<ApplicationsHome />} />
                                <Route exact path="" element={<ApplicationsHome />} />
                                <Route exact path="home" element={<ApplicationsHome />} />
                                <Route exact path="login" element={<ApplicationsHome />} />
                                <Route exact path="finance/*" element={<FinanceComponent />} />
                                <Route exact path="money-management/*" element={<MoneyManagementComponent />} />
                                <Route exact path="*" element={<NoPageFound />} />
                            </Routes>
                        </div>
                        <div className="col-0" />
                    </div>
                </BrowserRouter>
            </div>
        </div>
    )
}

export default MainComponent;