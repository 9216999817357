import { createSlice } from '@reduxjs/toolkit'

export const financeInvestorsSlice = createSlice({
    name: 'financeInvestors',
    initialState: [],
    reducers: {
      saveFinanceInvestorsData: (state, action) => {
        state = action.payload;
        return state;
      },
    },
  })
  
  // Action creators are generated for each case reducer function
  export const { saveFinanceInvestorsData } = financeInvestorsSlice.actions
  
  export default financeInvestorsSlice.reducer