import React, { useEffect, useState } from 'react';
import './css/add-customer.css';
import { execute_POST_API, execute_PUT_API, responseAuthorisation } from '../../../api/api-util.js';

export default function AddCustomer(props) {
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [phone, setPhone] = useState("");
    const [email, setEmail] = useState("");
    const [address, setAddress] = useState("");
    const [msg, setMsg] = useState("");
    const [errorMsg, setErrorMsg] = useState("");
    const [loading, setLoading] = useState(false);

    const fieldMaxLen = {
        firstName: 50,
        lastName: 50,
        phone: 10,
        email: 50,
        address: 200
    };

    const limitInputData = (field, value, trim) => {
        let limitedValue = value;
        if (fieldMaxLen[field]) {
            limitedValue = value.substring(0, fieldMaxLen[field]);
        }
        if (trim) {
            limitedValue = limitedValue.trim();
        }
        return limitedValue;
    }

    const inputData = (field, value, trim) => {
        const limitedValue = limitInputData(field, value, trim);
        switch (field) {
            case 'firstName':
                setFirstName(limitedValue);
                break;
            case 'lastName':
                setLastName(limitedValue);
                break;
            case 'phone':
                setPhone(limitedValue);
                break;
            case 'email':
                setEmail(limitedValue);
                break;
            case 'address':
                setAddress(limitedValue);
                break;
        }
    }

    const addNewUser = (event) => {
        event.preventDefault();
        //setMsg("Success");
        //setErrorMsg("Failed");
        let params = {
            firstname: firstName.trim(),
            lastname: lastName.trim(),
            phone: phone.trim(),
            email: email.trim(),
            address: address.trim()
        }
        setLoading(true);
        if (props.editType === "add") {
            execute_POST_API("/finance/customer/customer", null, params)
                .then(response => response.json())
                .then(result => {
                    if (responseAuthorisation(result)) {
                        if (result.iserror) {
                            alert(result.errormsg);
                        } else {
                            const data = result.data;
                            alert(data.msg);
                            if (data.status) {
                                clearForm();
                                props.closePopUp();
                            }
                        }
                    }
                    setLoading(false);
                })
                .catch(error => {
                    alert("Error Occurred");
                    console.log(error);
                    setLoading(false);
                });
        } else {
            params.id = props.recordToEdit.id;
            execute_PUT_API("/finance/customer/customer", null, params)
                .then(response => response.json())
                .then(result => {
                    if (responseAuthorisation(result)) {
                        if (result.iserror) {
                            alert(result.errormsg);
                        } else {
                            const data = result.data;
                            alert(data.msg);
                            if (data.status) {
                                clearForm();
                                props.closePopUp();
                            }
                        }
                    }
                    setLoading(false);
                })
                .catch(error => {
                    alert("Error Occurred");
                    console.log(error);
                    setLoading(false);
                });
        }

    }

    const clearForm = () => {
        setFirstName("");
        setLastName("");
        setPhone("");
        setEmail("");
        setAddress("");
    }

    useEffect(() => {
        if (msg !== "") {
            setTimeout(() => {
                setMsg("");
            }, 5000);
        };
        if (errorMsg !== "") {
            setTimeout(() => {
                setErrorMsg("");
            }, 5000);
        };
    }, [msg, errorMsg]);

    useEffect(() => {
        if (props.editType === "add") {
            clearForm();
        } else {
            const record = props.recordToEdit;
            setFirstName(record.first_name);
            setLastName(record.last_name);
            setPhone(record.phone);
            setEmail(record.email);
            setAddress(record.address);
        }
    }, [props]);

    return (
        <div>
            <div className='row'>
                <div className='col-12'>
                    <div className={"text-center " + (msg !== "" ? "text-primary" : (errorMsg !== "" ? "text-danger" : ""))}>
                        {msg !== "" ? msg : (errorMsg !== "" ? errorMsg : <span>&nbsp;</span>)}
                    </div>
                </div>
            </div>
            <form onSubmit={event => addNewUser(event)} onReset={clearForm}>
                <div className='row mt-1'>
                    <div className='col-12'>
                        <label className='form-label'><span className='text-danger'>* </span>Customer Name <small><em>({firstName.length} / {fieldMaxLen["firstName"]})</em></small> ~ <small><em>({lastName.length} / {fieldMaxLen["lastName"]})</em></small></label>
                        <div className='input-group'>
                            <input type='text' className='form-control' placeholder='Enter First Name' value={firstName} onChange={event => inputData("firstName", event.target.value, true)} required />
                            <input type='text' className='form-control' placeholder='Enter Last Name' value={lastName} onChange={event => inputData("lastName", event.target.value, false)} />
                        </div>
                    </div>
                </div>
                <div className='row mt-3'>
                    <div className='col-12'>
                        <label className='form-label'>Phone Number <small><em>({phone.length} / {fieldMaxLen["phone"]})</em></small></label>
                        <input type='number' className='form-control' placeholder='Enter Phone Number' value={phone} onChange={event => inputData("phone", event.target.value, true)} />
                    </div>
                </div>
                <div className='row mt-3'>
                    <div className='col-12'>
                        <label className='form-label'>Email <small><em>({email.length} / {fieldMaxLen["email"]})</em></small></label>
                        <input type='email' className='form-control' placeholder='Enter Email' value={email} onChange={event => inputData("email", event.target.value, true)} />
                    </div>
                </div>
                <div className='row mt-3'>
                    <div className='col-12'>
                        <label className='form-label'>Address <small><em>({address.length} / {fieldMaxLen["address"]})</em></small></label>
                        <textarea className='form-control' placeholder='Enter Address' value={address} onChange={event => inputData("address", event.target.value, false)} />
                    </div>
                </div>
                <div className='row mt-5 text-center'>
                    <div className='col-6'>
                        <button type="submit" className="btn btn-warning fullsizedbutton" disabled={loading}>
                            {loading ?
                                <span class="spinner-border spinner-border-sm" aria-hidden="true"></span>
                                :
                                props.editType === "add" ? "Add" : "Update"}
                        </button>
                    </div>
                    <div className='col-6'>
                        <button type="reset" className="btn btn-secondary fullsizedbutton">Clear</button>
                    </div>
                </div>
            </form>
        </div>
    )
}
