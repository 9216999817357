import React, { useEffect, useState } from 'react'
import { execute_GET_API, responseAuthorisation } from '../../../api/api-util';
import Alert from '../../Util/alert';

export default function FinanceDashboard() {
  const [moneyData, setMoneyData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [alertMsg, setAlertMsg] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [alertType, setAlertType] = useState("info");

  const fetchMoneyData = () => {
    const url = "/finance/management/dashboard_data";
    const params = "action=money_data";
    const full_url = url + "?" + params;
    setLoading(true);
    setShowAlert(false);
    execute_GET_API(full_url, null)
      .then(response => response.json())
      .then(result => {
        if (responseAuthorisation(result)) {
          if (result.iserror) {
            setShowAlert(true);
            setAlertMsg(result.errormsg);
            setAlertType("danger");
          } else {
            const data = result.data;
            setMoneyData(data);
          }
        }
        setLoading(false);
      })
      .catch(error => {
        setShowAlert(true);
        setAlertMsg("Error Occurred");
        setAlertType("danger");
        console.log(error);
        setLoading(false);
      });
  }

  useEffect(() => {
    fetchMoneyData();
  }, []);

  return (
    <div className='mx-auto' style={{ width: '95%' }}>
      {loading ?
        <div className="d-flex justify-content-center" style={{top: '40vh', position: 'relative'}}>
          <div className="spinner-border" role="status" />
          <div className='h3'>&nbsp;&nbsp;Loading Dashboard...</div>
        </div>
        :
        <div>
          <div className='row' style={{ display: (showAlert ? "" : "none") }}>
            <div className='col-lg-4 col-12 mx-auto'>
              <Alert alertMsg={alertMsg} showAlert={showAlert} alertType={alertType} setShowAlert={setShowAlert} />
            </div>
          </div>
          <div className='row p-2'>
            {moneyData.map((obj, index) => {
              const money_data = obj.money_data;
              return (
                <div className='col-12 col-lg-4 border mt-3' style={{ borderRadius: '1rem' }}>
                  <div className='h3 text-center text-primary mt-2'><strong>{obj.first_name + " " + obj.last_name}</strong></div><hr />
                  <table className='table table-borderless'>
                    <thead>
                      <tr className='bg-info'>
                        <th>Description</th>
                        <th className='text-end'>Amount</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td><strong>Total Investment</strong><span className='text-danger'> <small>(A)</small></span></td>
                        <td className='text-end'>{money_data.investment}</td>
                      </tr>
                      <tr>
                        <td><strong>Total Withdrawl</strong><span className='text-danger'> <small>(B)</small></span></td>
                        <td className='text-end'>{money_data.withdrawl}</td>
                      </tr>
                      <tr>
                        <td><strong>Current Capital</strong><span className='text-danger'> <small>(C = A-B)</small></span></td>
                        <td className='text-end'>{money_data.capital}</td>
                      </tr>
                      <tr>
                        <td><strong>Interest Received Till Date</strong><span className='text-danger'> <small>(D)</small></span></td>
                        <td className='text-end text-primary'>{money_data.interest_gained}</td>
                      </tr>
                      <tr>
                        <td><strong>Interest Pending Outside</strong><span className='text-danger'> <small>(E)</small></span></td>
                        <td className='text-end text-danger'>{money_data.interest_pending}</td>
                      </tr>
                      <tr>
                        <td><strong>Total Interest</strong><span className='text-danger'> <small>(F = D+E)</small></span></td>
                        <td className='text-end'>{money_data.total_interest}</td>
                      </tr>
                      <tr>
                        <td><strong>Capital Pending Outside</strong><span className='text-danger'> <small>(G)</small></span></td>
                        <td className='text-end text-danger'>{money_data.pending_capital}</td>
                      </tr>
                      <tr>
                        <td><strong>Amount In Hand</strong><span className='text-danger'> <small>(H)</small></span></td>
                        <td className='text-end text-primary'><strong>{money_data.amount_in_hand}</strong></td>
                      </tr>
                      <tr>
                        <td><strong>Active Accounts</strong><span className='text-danger'> <small>(I)</small></span></td>
                        <td className='text-end'>{money_data.active_accounts_count}</td>
                      </tr>
                      <tr>
                        <td><strong>Closed Accounts</strong><span className='text-danger'> <small>(J)</small></span></td>
                        <td className='text-end'>{money_data.closed_accounts_count}</td>
                      </tr>
                      <tr>
                        <td><strong>Total Accounts</strong><span className='text-danger'> <small>(K = I+J)</small></span></td>
                        <td className='text-end'>{money_data.active_accounts_count + money_data.closed_accounts_count}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              )
            })}
          </div>
        </div>
      }
    </div>
  )
}
