import React, { useEffect, useState } from 'react'

export default function MoneyDenominator() {
    const [twoThousands, setTwoThousands] = useState("");
    const [twoThousandsResult, setTwoThousandsResult] = useState(0);
    const [thousands, setThousands] = useState("");
    const [thousandsResult, setThousandsResult] = useState(0);
    const [fiveHundreds, setFiveHundreds] = useState("");
    const [fiveHundredsResult, setFiveHundredsResult] = useState(0);
    const [twoHundreds, setTwoHundreds] = useState("");
    const [twoHundredsResult, setTwoHundredsResult] = useState(0);
    const [hundreds, setHundreds] = useState("");
    const [hundredsResult, setHundredsResult] = useState(0);
    const [fiftys, setFiftys] = useState("");
    const [fiftysResult, setFiftysResult] = useState(0);
    const [twentys, setTwentys] = useState("");
    const [twentysResult, setTwentysResult] = useState(0);
    const [tens, setTens] = useState("");
    const [tensResult, setTensResult] = useState(0);
    const [five, setFive] = useState("");
    const [fiveResult, setFiveResult] = useState(0);
    const [two, setTwo] = useState("");
    const [twoResult, setTwoResult] = useState(0);
    const [one, setOne] = useState("");
    const [oneResult, setOneResult] = useState(0);
    const [totalCount, setTotalCount] = useState(0);
    const [totalAmount, setTotalAmount] = useState(0);
    const [clearBtDisabled, setClearBtDisabled] = useState(false);

    const multiplyTwoThousands = (value) => {
        setTwoThousands(value);
        let temp = value;
        if (temp.trim() === "") {
            temp = 0;
        }
        const resultTemp = temp * 2000;
        setTwoThousandsResult(resultTemp);
    }

    const multiplyThousands = (value) => {
        setThousands(value);
        let temp = value;
        if (temp.trim() === "") {
            temp = 0;
        }
        const resultTemp = temp * 1000;
        setThousandsResult(resultTemp);
    }

    const multiplyFiveHundreds = (value) => {
        setFiveHundreds(value);
        let temp = value;
        if (temp.trim() === "") {
            temp = 0;
        }
        const resultTemp = temp * 500;
        setFiveHundredsResult(resultTemp);
    }

    const multiplyTwoHundreds = (value) => {
        setTwoHundreds(value);
        let temp = value;
        if (temp.trim() === "") {
            temp = 0;
        }
        const resultTemp = temp * 200;
        setTwoHundredsResult(resultTemp);
    }

    const multiplyHundreds = (value) => {
        setHundreds(value);
        let temp = value;
        if (temp.trim() === "") {
            temp = 0;
        }
        const resultTemp = temp * 100;
        setHundredsResult(resultTemp);
    }

    const multiplyFiftys = (value) => {
        setFiftys(value);
        let temp = value;
        if (temp.trim() === "") {
            temp = 0;
        }
        const resultTemp = temp * 50;
        setFiftysResult(resultTemp);
    }

    const multiplyTwentys = (value) => {
        setTwentys(value);
        let temp = value;
        if (temp.trim() === "") {
            temp = 0;
        }
        const resultTemp = temp * 20;
        setTwentysResult(resultTemp);
    }

    const multiplyTens = (value) => {
        setTens(value);
        let temp = value;
        if (temp.trim() === "") {
            temp = 0;
        }
        const resultTemp = temp * 10;
        setTensResult(resultTemp);
    }

    const multiplyFives = (value) => {
        setFive(value);
        let temp = value;
        if (temp.trim() === "") {
            temp = 0;
        }
        const resultTemp = temp * 5;
        setFiveResult(resultTemp);
    }

    const multiplyTwos = (value) => {
        setTwo(value);
        let temp = value;
        if (temp.trim() === "") {
            temp = 0;
        }
        const resultTemp = temp * 2;
        setTwoResult(resultTemp);
    }

    const multiplyOnes = (value) => {
        setOne(value);
        let temp = value;
        if (temp.trim() === "") {
            temp = 0;
        }
        const resultTemp = temp * 1;
        setOneResult(resultTemp);
    }

    const clear = () => {
        setClearBtDisabled(true);
        setTwoThousands("");
        setTwoThousandsResult(0);
        setThousands("");
        setThousandsResult(0);
        setFiveHundreds("");
        setFiveHundredsResult(0);
        setTwoHundreds("");
        setTwoHundredsResult(0);
        setHundreds("");
        setHundredsResult(0);
        setFiftys("");
        setFiftysResult(0);
        setTwentys("");
        setTwentysResult(0);
        setTens("");
        setTensResult(0);
        setFive("");
        setFiveResult(0);
        setTwo("");
        setTwoResult(0);
        setOne("");
        setOneResult(0);
        setClearBtDisabled(false);
    }

    useEffect(() => {
        const totalCountTemp = parseFloat(twoThousands.trim() === "" ? 0 : twoThousands) + parseFloat(thousands.trim() === "" ? 0 : thousands) + parseFloat(fiveHundreds.trim() === "" ? 0 : fiveHundreds) + parseFloat(twoHundreds.trim() === "" ? 0 : twoHundreds) + parseFloat(hundreds.trim() === "" ? 0 : hundreds) + parseFloat(fiftys.trim() === "" ? 0 : fiftys) + parseFloat(twentys.trim() === "" ? 0 : twentys) + parseFloat(tens.trim() === "" ? 0 : tens) + parseFloat(five.trim() === "" ? 0 : five) + parseFloat(two.trim() === "" ? 0 : two) + parseFloat(one === "" ? 0 : one);
        const totalAmountTemp = twoThousandsResult + thousandsResult + fiveHundredsResult + twoHundredsResult + hundredsResult + fiftysResult + twentysResult + tensResult + fiveResult + twoResult + oneResult;
        setTotalCount(totalCountTemp);
        setTotalAmount(totalAmountTemp);
    }, [twoThousands, thousands, fiveHundreds, twoHundreds, hundreds, fiftys, twentys, tens, five, two, one]);

    return (
        <div>
            <div className='row mt-3'>
                <div className='col-12 col-lg-4 h3 text-center mx-auto'>
                    <div style={{ border: '1.5px ridge red', borderRadius: '20px', padding: '5px', boxShadow: '0rem 0.25rem 1rem white' }}>
                        <div style={{ border: '0.10rem ridge orange', borderRadius: '15px', boxShadow: '0rem 0rem 0.1rem 0.3rem white', backgroundImage: 'linear-gradient(180deg, red, #d65252)' }}>
                            Money Denominator
                        </div>
                    </div>
                </div>
            </div>
            <div className='row mt-2 text-dark'>
                <div className='col-12 col-lg-4 fs-5 text-center mx-auto'>
                    <strong>
                        <div className='bg-dark text-light border border-light rounded-3'>
                            <div className='row'>
                                <div className='col-5 text-end'>Total Count</div>
                                <div className='col-1 text-end'>:</div>
                                <div className='col-5 text-end'>{totalCount}</div>
                            </div>
                            <div className='row'>
                                <div className='col-5 text-end'>Total Amount</div>
                                <div className='col-1 text-end'>:</div>
                                <div className='col-5 text-end'>{totalAmount}</div>
                            </div>
                        </div>
                    </strong>
                </div>
            </div>
            <div className='row mt-2'>
                <div className='col-12 col-lg-4 mx-auto border border-light rounded' style={{height: '25.5rem', overflow: 'auto'}}>
                    <div className='row mt-3 text-dark'>
                        <div className='col-12 text-center mx-auto'>
                            <div className='row'>
                                <div className='col-3 text-end' style={{ justifyContent: 'center', display: 'flex', flexDirection: 'column' }}>
                                    <strong>2000 X</strong>
                                </div>
                                <div className='col-3'>
                                    <input className='form-control text-end' type='number' value={twoThousands} onChange={event => multiplyTwoThousands(event.target.value)} />
                                </div>
                                <div className='col-1 text-center' style={{ justifyContent: 'center', display: 'flex', flexDirection: 'column' }}>
                                    <strong>=</strong>
                                </div>
                                <div className='col-5'>
                                    <input className='form-control text-end' type='number' value={twoThousandsResult} disabled />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='row mt-2 text-dark'>
                        <div className='col-12 text-center mx-auto'>
                            <div className='row'>
                                <div className='col-3 text-end' style={{ justifyContent: 'center', display: 'flex', flexDirection: 'column' }}>
                                    <strong>1000 X</strong>
                                </div>
                                <div className='col-3'>
                                    <input className='form-control text-end' type='number' value={thousands} onChange={event => multiplyThousands(event.target.value)} />
                                </div>
                                <div className='col-1 text-center' style={{ justifyContent: 'center', display: 'flex', flexDirection: 'column' }}>
                                    <strong>=</strong>
                                </div>
                                <div className='col-5'>
                                    <input className='form-control text-end' type='number' value={thousandsResult} disabled />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='row mt-2 text-dark'>
                        <div className='col-12 text-center mx-auto'>
                            <div className='row'>
                                <div className='col-3 text-end' style={{ justifyContent: 'center', display: 'flex', flexDirection: 'column' }}>
                                    <strong>500 X</strong>
                                </div>
                                <div className='col-3'>
                                    <input className='form-control text-end' type='number' value={fiveHundreds} onChange={event => multiplyFiveHundreds(event.target.value)} />
                                </div>
                                <div className='col-1 text-center' style={{ justifyContent: 'center', display: 'flex', flexDirection: 'column' }}>
                                    <strong>=</strong>
                                </div>
                                <div className='col-5'>
                                    <input className='form-control text-end' type='number' value={fiveHundredsResult} disabled />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='row mt-2 text-dark'>
                        <div className='col-12 text-center mx-auto'>
                            <div className='row'>
                                <div className='col-3 text-end' style={{ justifyContent: 'center', display: 'flex', flexDirection: 'column' }}>
                                    <strong>200 X</strong>
                                </div>
                                <div className='col-3'>
                                    <input className='form-control text-end' type='number' value={twoHundreds} onChange={event => multiplyTwoHundreds(event.target.value)} />
                                </div>
                                <div className='col-1 text-center' style={{ justifyContent: 'center', display: 'flex', flexDirection: 'column' }}>
                                    <strong>=</strong>
                                </div>
                                <div className='col-5'>
                                    <input className='form-control text-end' type='number' value={twoHundredsResult} disabled />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='row mt-2 text-dark'>
                        <div className='col-12 text-center mx-auto'>
                            <div className='row'>
                                <div className='col-3 text-end' style={{ justifyContent: 'center', display: 'flex', flexDirection: 'column' }}>
                                    <strong>100 X</strong>
                                </div>
                                <div className='col-3'>
                                    <input className='form-control text-end' type='number' value={hundreds} onChange={event => multiplyHundreds(event.target.value)} />
                                </div>
                                <div className='col-1 text-center' style={{ justifyContent: 'center', display: 'flex', flexDirection: 'column' }}>
                                    <strong>=</strong>
                                </div>
                                <div className='col-5'>
                                    <input className='form-control text-end' type='number' value={hundredsResult} disabled />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='row mt-2 text-dark'>
                        <div className='col-12 text-center mx-auto'>
                            <div className='row'>
                                <div className='col-3 text-end' style={{ justifyContent: 'center', display: 'flex', flexDirection: 'column' }}>
                                    <strong>50 X</strong>
                                </div>
                                <div className='col-3'>
                                    <input className='form-control text-end' type='number' value={fiftys} onChange={event => multiplyFiftys(event.target.value)} />
                                </div>
                                <div className='col-1 text-center' style={{ justifyContent: 'center', display: 'flex', flexDirection: 'column' }}>
                                    <strong>=</strong>
                                </div>
                                <div className='col-5'>
                                    <input className='form-control text-end' type='number' value={fiftysResult} disabled />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='row mt-2 text-dark'>
                        <div className='col-12 text-center mx-auto'>
                            <div className='row'>
                                <div className='col-3 text-end' style={{ justifyContent: 'center', display: 'flex', flexDirection: 'column' }}>
                                    <strong>20 X</strong>
                                </div>
                                <div className='col-3'>
                                    <input className='form-control text-end' type='number' value={twentys} onChange={event => multiplyTwentys(event.target.value)} />
                                </div>
                                <div className='col-1 text-center' style={{ justifyContent: 'center', display: 'flex', flexDirection: 'column' }}>
                                    <strong>=</strong>
                                </div>
                                <div className='col-5'>
                                    <input className='form-control text-end' type='number' value={twentysResult} disabled />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='row mt-2 text-dark'>
                        <div className='col-12 text-center mx-auto'>
                            <div className='row'>
                                <div className='col-3 text-end' style={{ justifyContent: 'center', display: 'flex', flexDirection: 'column' }}>
                                    <strong>10 X</strong>
                                </div>
                                <div className='col-3'>
                                    <input className='form-control text-end' type='number' value={tens} onChange={event => multiplyTens(event.target.value)} />
                                </div>
                                <div className='col-1 text-center' style={{ justifyContent: 'center', display: 'flex', flexDirection: 'column' }}>
                                    <strong>=</strong>
                                </div>
                                <div className='col-5'>
                                    <input className='form-control text-end' type='number' value={tensResult} disabled />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='row mt-2 text-dark'>
                        <div className='col-12 text-center mx-auto'>
                            <div className='row'>
                                <div className='col-3 text-end' style={{ justifyContent: 'center', display: 'flex', flexDirection: 'column' }}>
                                    <strong>5 X</strong>
                                </div>
                                <div className='col-3'>
                                    <input className='form-control text-end' type='number' value={five} onChange={event => multiplyFives(event.target.value)} />
                                </div>
                                <div className='col-1 text-center' style={{ justifyContent: 'center', display: 'flex', flexDirection: 'column' }}>
                                    <strong>=</strong>
                                </div>
                                <div className='col-5'>
                                    <input className='form-control text-end' type='number' value={fiveResult} disabled />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='row mt-2 text-dark'>
                        <div className='col-12 text-center mx-auto'>
                            <div className='row'>
                                <div className='col-3 text-end' style={{ justifyContent: 'center', display: 'flex', flexDirection: 'column' }}>
                                    <strong>2 X</strong>
                                </div>
                                <div className='col-3'>
                                    <input className='form-control text-end' type='number' value={two} onChange={event => multiplyTwos(event.target.value)} />
                                </div>
                                <div className='col-1 text-center' style={{ justifyContent: 'center', display: 'flex', flexDirection: 'column' }}>
                                    <strong>=</strong>
                                </div>
                                <div className='col-5'>
                                    <input className='form-control text-end' type='number' value={twoResult} disabled />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='row mt-2 mb-2 text-dark'>
                        <div className='col-12 text-center mx-auto'>
                            <div className='row'>
                                <div className='col-3 text-end' style={{ justifyContent: 'center', display: 'flex', flexDirection: 'column' }}>
                                    <strong>1 X</strong>
                                </div>
                                <div className='col-3'>
                                    <input className='form-control text-end' type='number' value={one} onChange={event => multiplyOnes(event.target.value)} />
                                </div>
                                <div className='col-1 text-center' style={{ justifyContent: 'center', display: 'flex', flexDirection: 'column' }}>
                                    <strong>=</strong>
                                </div>
                                <div className='col-5'>
                                    <input className='form-control text-end' type='number' value={oneResult} disabled />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='row mt-4'>
                <div className='col-12 col-lg-4 mx-auto'>
                    <button type='button' className='btn btn-primary fs-4' style={{width: '100%'}} onClick={clear} disabled={clearBtDisabled}>Clear</button>
                </div>
            </div>
        </div>
    )
}
