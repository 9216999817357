import React, { useEffect, useState } from 'react'
import MultiSelectLookupFieldComponent from '../../Util/multiselect-lookup-field-component';
import RolesDropdownComponent from '../../Util/roles-dropdown-component';
import UsersDropdownComponent from '../../Util/users-dropdown-component';

export default function AddAccount() {
    const [seletecRoleIds, setSelectedRolesIds] = useState([]);
    const [seletedRoleValues, setSelectedRoleValues] = useState([]);
    const [seletecUserIds, setSelectedUserIds] = useState([]);
    const [seletedUserValues, setSelectedUserValues] = useState([]);

    useEffect(() => {
        
    }, []);
    
    return (
        <div>
            <div className='text-center text-success h3'>
                <strong>Add New Account</strong><hr/>
            </div>
            <div className='row'>
                <div className='col-12 text-dark'>
                    <div className="form-floating mb-3">
                        <input type="text" class="form-control" id="accountName" placeholder="Account Name" />
                        <label for="accountName">Account Name</label>
                    </div>
                    <div className="form-floating mb-3">
                        <input type="text" class="form-control" id="accountType" placeholder="Account Type" />
                        <label for="accountType">Account Type</label>
                    </div>
                </div>
            </div>
            <div className='row mb-3'>
                <div className='col-12'>
                    <RolesDropdownComponent selectType="multiple" setSelectedRolesIds={setSelectedRolesIds} setSelectedRoleValues={setSelectedRoleValues} />
                </div>
            </div>
            <div className='row'>
                <div className='col-12'>
                    <UsersDropdownComponent selectType="multiple" setSelectedUserIds={setSelectedUserIds} setSelectedUserValues={setSelectedUserValues} />
                </div>
            </div>
        </div>
    )
}
