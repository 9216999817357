import React from 'react'

export default function AuthenticationProcessingComponent() {
    return (
        <div className='text-center' style={{ marginTop: '40vh' }}>
            <div className='row'>
                <div className='col-10 offset-1 col-md-8 offset-md-2 col-lg-4 offset-lg-4'>
                    <div className='h4'>Authenticating... Please Wait....</div>
                    <div className="progress" role="progressbar" aria-label="Animated striped example" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100">
                        <div className="progress-bar progress-bar-striped progress-bar-animated bg-danger" style={{ width: '100%' }}></div>
                    </div>
                </div>
            </div>
        </div>
    )
}
