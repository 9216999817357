import React, { useEffect, useState } from 'react';
import '../css/Mangement/transaction-summary.css';
import Investment from './investment';
import Withdrawl from './withdrawl';
import Paging from '../../Util/paging';
import { execute_GET_API } from '../../../api/api-util';
import { useSelector } from 'react-redux';
import { getMonthFirstDate, getMonthLastDate } from '../../Util/common-util';
import InvestorDropdownComponent from '../Util/investor-dropdown-component';
import TableLoadingComponent from '../Util/table-loading-component';
import TableNoDataComponent from '../Util/table-nodata-component';

export default function TransactionSummary() {
    const [investor, setInvestor] = useState(0);
    const [startRangeDate, setStartRangeDate] = useState(getMonthFirstDate());
    const [endRangeDate, setEndRangeDate] = useState(getMonthLastDate);
    const [debtTransactionsChkBox, setDebtTransactionsChkBox] = useState(true);
    const [investmentTransactionsChkBox, setInvestmentTransactionsChkBox] = useState(false);
    const [withdrawlTransactionsChkBox, setWithdrawlTransactionsChkBox] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [recordsPerPage, setRecordsPerPage] = useState(10);
    const [totalRecords, setTotalRecords] = useState(0);
    const pagesLimitToDisplay = 7;
    const [transactions, setTransactions] = useState([]);
    const [loading, setLoading] = useState(false);
    const [recordsPerPageValues, setRecordsPerPageValues] = useState([1, 2, 5, 10, 20]);
    const [fetchTransactionsBtClicked, setFetchTransactionsBtClicked] = useState(false);

    const fetchTransactions = () => {
        //alert(recordsPerPage);
        if (startRangeDate === "" || endRangeDate === "") {
            alert("Please select 'From Date' and 'To Date'");
            return;
        }
        if (investor === 0) {
            alert("Please choose 'Investor'");
            return;
        }
        if (!debtTransactionsChkBox && !investmentTransactionsChkBox && !withdrawlTransactionsChkBox) {
            alert("Please select atlease one 'Transaction Type'");
            return;
        }
        const from = ((currentPage - 1) * recordsPerPage);
        const limit = recordsPerPage;
        const params = {
            from,
            limit,
            types: {
                debts: debtTransactionsChkBox,
                investments: investmentTransactionsChkBox,
                withdrawls: withdrawlTransactionsChkBox
            },
            fromdate: startRangeDate,
            todate: endRangeDate,
            investor
        };
        const url = "/finance/management/transactions?params=" + JSON.stringify(params);
        setLoading(true);
        //setTotalRecords(0);
        //setTransactions([]);
        execute_GET_API(url, null, null)
            .then(response => response.json())
            .then(result => {
                if (result.iserror) {
                    alert(result.errormsg);
                } else {
                    const data = result.data;
                    setTotalRecords(data.count);
                    setTransactions(data.records);
                }
                setLoading(false);
            })
            .catch(error => {
                alert("Error Occurred");
                console.log(error);
                setLoading(false);
                setTotalRecords(0);
                setTransactions([]);
            });
    }

    const setCurrentPageFunc = (page) => {
        setCurrentPage(page);
        //fetchTransactions();
    }

    const setRecordsPerPageFunc = (recordCount) => {
        setCurrentPage(1);
        setRecordsPerPage(recordCount);
        //fetchTransactions();
    }

    const fetchTransactionsFunc = () => {
        setCurrentPage(1);
        setFetchTransactionsBtClicked(true);
    }

    useEffect(() => {
        if ((currentPage === 1 && totalRecords !== 0) || currentPage > 1) {
            fetchTransactions();
        }
    }, [currentPage, recordsPerPage]);

    useEffect(() => {
        if(fetchTransactionsBtClicked){
            setFetchTransactionsBtClicked(false);
            fetchTransactions();
        }
    }, [fetchTransactionsBtClicked]);

    return (
        <div className=''>
            <div className='row text-center mx-auto mt-1'>
                <div className='col-6 col-lg-4 offset-lg-2 mt-3'>
                    <button className='btn btn-success fullsizedbutton' data-bs-toggle="modal" data-bs-target="#investPopUp">Investment</button>
                    <div className="modal fade" id="investPopUp" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="investPopUpLabel" aria-hidden="true">
                        <div className="modal-dialog">
                            <div className="modal-content">
                                <div className="modal-header" style={{ backgroundColor: '#0088797d' }}>
                                    <h1 className="modal-title fs-5" id="investPopUpLabel">Investment</h1>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div className="modal-body" style={{ backgroundColor: '#0088797d' }}>
                                    <Investment />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='col-6 col-lg-4 mt-3'>
                    <button className='btn btn-danger fullsizedbutton' data-bs-toggle="modal" data-bs-target="#withdrawlPopUp">Withdrawl</button>
                    <div className="modal fade" id="withdrawlPopUp" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="withdrawlPopUpLabel" aria-hidden="true">
                        <div className="modal-dialog">
                            <div className="modal-content">
                                <div className="modal-header" style={{ backgroundColor: '#0088797d' }}>
                                    <h1 className="modal-title fs-5" id="withdrawlPopUpLabel">Withdrawl</h1>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div className="modal-body" style={{ backgroundColor: '#0088797d' }}>
                                    <Withdrawl />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div><hr />
            <div className='row'>
                <div className='col-lg-4 mx-auto border-end' id='transaction-date-range-block'>
                    <div className='row'>
                        <div className='col-5 mx-auto'>
                            <label className='form-label'>From Date</label>
                            <input type='date' className='form-control' value={startRangeDate} onChange={event => setStartRangeDate(event.target.value)} />
                        </div>
                        <div className='col-5 mx-auto'>
                            <label className='form-label'>To Date</label>
                            <input type='date' className='form-control' value={endRangeDate} onChange={event => setEndRangeDate(event.target.value)} />
                        </div>
                    </div>
                </div>
                <div className='col-lg-4 mx-auto border-end' id='investor-account-block'>
                    <div className='mx-auto' style={{ width: '90%' }}>
                        <label className='form-label'>Choose Investor</label>
                        <InvestorDropdownComponent key={"transaction_summary"} setInvestor={setInvestor} useLocalStorage={true} />
                    </div>
                </div>
                <div className='col-lg-4' id='transaction-type-block'>
                    <div className='mx-auto' style={{ width: '90%' }}>
                        <label className='form-label'>Choose Transaction Type</label>
                        <div className='row'>
                            <div className='col-2 col-lg-1'>
                                <div className="form-check">
                                    <input className="form-check-input border border-danger transaction-type-chkbox" type="checkbox" id="DebtTransactionsChkBox" checked={debtTransactionsChkBox} onClick={() => setDebtTransactionsChkBox(!debtTransactionsChkBox)} />
                                    <label className="form-check-label" for="DebtTransactionsChkBox">Debts</label>
                                </div>
                            </div>
                            <div className='col-3 col-lg-1 mx-auto'>
                                <div className="form-check">
                                    <input className="form-check-input border border-danger transaction-type-chkbox" type="checkbox" id="InvestmentTransactionsChkBox" checked={investmentTransactionsChkBox} onClick={() => setInvestmentTransactionsChkBox(!investmentTransactionsChkBox)} />
                                    <label className="form-check-label" for="InvestmentTransactionsChkBox">Investments</label>
                                </div>
                            </div>
                            <div className='col-4 col-lg-3 mx-auto'>
                                <div className="form-check">
                                    <input className="form-check-input border border-danger transaction-type-chkbox" type="checkbox" id="WithdrawlTransactionsChkBox" checked={withdrawlTransactionsChkBox} onClick={() => setWithdrawlTransactionsChkBox(!withdrawlTransactionsChkBox)} />
                                    <label className="form-check-label" for="WithdrawlTransactionsChkBox">Withdrawls</label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='row mt-3'>
                <div className='col-11 col-lg-4 mx-auto'>
                    <button className='btn btn-warning fullsizedbutton' onClick={fetchTransactionsFunc} disabled={loading}>
                        {loading ?
                            <span class="spinner-border spinner-border-sm" aria-hidden="true"></span>
                            :
                            "Fetch Transactions"
                        }
                    </button>
                </div>
            </div>
            <hr />
            <div className='row mt-4'>
                <div className='col-12 col-lg-4 text-center'>
                    <div>Total Records : {totalRecords}</div>
                </div>
                <div className='col-12 col-lg-4 text-center'>
                    <Paging key={"trans-summary"} setCurrentPage={setCurrentPageFunc} totalRecords={totalRecords} recordsPerPage={recordsPerPage} currentPage={currentPage} pagesLimitToDisplay={pagesLimitToDisplay} />
                </div>
                <div className='col-12 col-lg-4' style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <select className='form-select' value={recordsPerPage} onChange={event => setRecordsPerPageFunc(event.target.value)} style={{ width: 'max-content' }}>
                        {recordsPerPageValues.map((value, index) => {
                            return (
                                <option value={value}>{value}</option>
                            )
                        })}
                    </select>
                </div>
            </div>
            <div className='row'>
                <div className='col-12' style={{ overflow: 'auto' }}>
                    <table className='table table-responsive' style={{ overflow: 'auto', color: 'white' }}>
                        <thead>
                            <tr className=''>
                                <th></th>
                                <th className='text-end'>#</th>
                                <th>Date</th>
                                <th>Category</th>
                                <th>Description</th>
                                <th className='text-end'>Credit</th>
                                <th className='text-end'>Debit</th>
                                <th className='text-end'>Balance</th>
                            </tr>
                        </thead>

                        {loading ?
                            <TableLoadingComponent colspan={8} />
                            :
                            transactions.length === 0 ?
                                <TableNoDataComponent colspan={8} />
                                :
                                <tbody>
                                    {transactions.map((transaction, index) => {
                                        const currentIndex = (((currentPage - 1) * recordsPerPage) + (index + 1));
                                        return (
                                            <tr style={{ textWrap: 'nowrap' }}>
                                                <td></td>
                                                <td className='text-end'>{currentIndex}</td>
                                                <td>{transaction.date + " " + transaction.time}</td>
                                                <td>{transaction.category}</td>
                                                <td>{transaction.description}</td>
                                                <td className='text-end'>{transaction.credit === null ? "" : "+" + transaction.credit}</td>
                                                <td className='text-end'>{transaction.debit === null ? "" : "-" + transaction.debit}</td>
                                                <td className='text-end'>{transaction.balance}</td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                        }
                    </table>
                </div>
            </div>
        </div>
    )
}
