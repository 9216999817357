import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { execute_POST_API } from '../../../api/api-util';
import InvestorDropdownComponent from '../Util/investor-dropdown-component';

export default function Investment() {
    const [datetime, setDateTime] = useState("");
    const [investor, setInvestor] = useState(0);
    const [amount, setAmount] = useState("");

    const addInvestment = (event) => {
        event.preventDefault();
        if (datetime === "") {
            alert("Please Select Date and Time");
            return;
        }
        if (investor === 0) {
            alert("Please Choose Investor");
            return;
        }
        if (amount === "" || amount < 0) {
            alert("Please enter amount greater than zero");
            return;
        }
        const body = {
            datetime,
            investor,
            amount
        };
        execute_POST_API("/finance/management/transactions?action=investment", null, body)
            .then(response => response.json())
            .then(result => {
                if (result.iserror) {
                    alert(result.errormsg);
                } else {
                    const data = result.data;
                    alert(data);
                    clearForm();
                }
            })
            .catch(error => {
                alert("Error Occurred");
                console.log(error);
            });
    }

    const clearForm = () => {
        setDateTime("");
        setInvestor(0);
        setAmount("");
    }

    return (
        <div className='text-start'>
            <form className='form' onSubmit={event => addInvestment(event)}>
                <div className='row mb-3'>
                    <div className='col-12'>
                        <label className='form-label'><span className='text-danger'>* </span>Date</label>
                        <input type="datetime-local" class="form-control" required value={datetime} onChange={event => setDateTime(event.target.value)}/>
                    </div>
                </div>
                <div className='row mb-3'>
                    <div className='col-12'>
                        <label className='form-label'><span className='text-danger'>* </span>Investor Account</label>
                        <InvestorDropdownComponent key={"investment"} investor={investor} setInvestor={setInvestor} />
                    </div>
                </div>
                <div className='row mb-3'>
                    <div className='col-12'>
                        <label className='form-label'><span className='text-danger'>* </span>Amount</label>
                        <input type="number" class="form-control" value={amount} onChange={event => setAmount(event.target.value)} required />
                    </div>
                </div>
                <div className='row mb-3'>
                    <div className='col-6'>
                        <button type='submit' className='btn btn-warning fullsizedbutton'>Invest</button>
                    </div>
                    <div className='col-6'>
                        <button type='reset' className='btn btn-secondary fullsizedbutton' onClick={clearForm}>Clear</button>
                    </div>
                </div>
            </form>
        </div>
    )
}
