import React, { useEffect, useState } from 'react';
import './css/delete-account.css';
import { execute_DELETE_API, execute_PUT_API, responseAuthorisation } from '../../../api/api-util';

export default function CloseAccount(props) {
    const [record, setRecord] = useState(props.recordToDelete);
    const [loading, setLoading] = useState(false);

    const closeRecord = () => {
        const params = {
            id: record.id
        }
        setLoading(true);
        execute_PUT_API("/finance/account/accounts?action=close", null, params)
            .then(response => response.json())
            .then(result => {
                if (responseAuthorisation(result)) {
                    if (result.iserror) {
                        alert(result.errormsg);
                    } else {
                        const data = result.data;
                        alert(data.msg);
                    }
                }
                props.closePopUp();
                setLoading(false);
            })
            .catch(error => {
                alert("Error Occurred");
                console.log(error);
                props.closePopUp();
                setLoading(false);
            });
    }

    useEffect(() => {
        setRecord(props.recordToDelete);
    }, [props]);

    return (
        <div>
            <div className='text-center'>
                <span className='text-primary fs-2'><em>Are you Sure To Close The Account </em><br /><strong>"{record.type + " - " + record.amount}<br />{record.date_time}"</strong></span>
                <div className='row mt-3 text-center'>
                    <div className='col-12'>
                        <button type="button" className="btn btn-warning fullsizedbutton" onClick={closeRecord} disabled={loading}>
                            {loading ?
                                <span class="spinner-border spinner-border-sm" aria-hidden="true"></span>
                                : "Close"
                            }
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}
