import React, { useEffect, useState } from 'react'
import { execute_GET_API, responseAuthorisation } from '../../../api/api-util';
import LookupFieldComponent from './lookup-field-component';

export default function CustomersDropdownComponent(props) {
    const [customers, setCustomers] = useState([]);
    const [customersCount, setCustomersCount] = useState(0);
    const [loading, setLoading] = useState(false);
    const primayKeyField = "id";
    const fieldsToShowInDropdown = ["first_name", "last_name"];

    const getCustomersCount = () => {
        const url = "/finance/customer/customer";
        const params = "action=customers_count";
        const full_url = url + "?" + params;
        setLoading(true);
        execute_GET_API(full_url, null)
            .then(response => response.json())
            .then(result => {
                if (responseAuthorisation(result)) {
                    if (result.iserror) {
                        alert(result.errormsg);
                    } else {
                        const data = result.data;
                        setCustomersCount(data);
                    }
                    setLoading(false);
                }
                setLoading(false);
            })
            .catch(error => {
                alert("Error Occurred");
                console.log(error);
                setLoading(false);
            });
    }

    const getCustomers = () => {
        const recordsPerCall = 50;
        var loopCount = Math.floor(customersCount / recordsPerCall);
        const remainder = customersCount % recordsPerCall;
        if (remainder > 0) {
            loopCount += 1;
        }
        let customerList = [];
        for (let loop = 1; loop <= loopCount; loop++) {
            const from = ((loop - 1) * recordsPerCall);
            const limit = ((loop * recordsPerCall) - 1);
            const url = "/finance/customer/customer";
            const params = "from=" + from + "&limit=" + limit + "&action=customers_list";
            const full_url = url + "?" + params;
            setLoading(true);
            execute_GET_API(full_url, null)
                .then(response => response.json())
                .then(result => {
                    if (responseAuthorisation(result)) {
                        if (result.iserror) {
                            alert(result.errormsg);
                        } else {
                            const data = result.data;
                            setCustomers(customers.concat(data));
                        }
                        setLoading(false);
                    }
                    setLoading(false);
                })
                .catch(error => {
                    alert("Error Occurred");
                    console.log(error);
                    setLoading(false);
                });
        }
        //setCustomers(customerList);
    }

    useEffect(() => {
        getCustomersCount();
    }, []);

    useEffect(() => {
        getCustomers();
    }, [customersCount]);

    return (
        <div>
            <LookupFieldComponent key={"customers_dropdown"} fields={fieldsToShowInDropdown} primayKeyField={primayKeyField} records={customers} disabled={false} placeHolder={"Choose Customer"} loading={loading} setSelectedKey={props.setSelectedCustomerId} setSelectedValue={props.setSelectedCustomerValue}/>
        </div>
    )
}
