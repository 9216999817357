import React, { useEffect, useState } from 'react';

export default function Paging(props) {
    const [currentPage, setCurrentPage] = useState(1);
    const [pages, setPages] = useState([]);
    const [pageCount, setPageCount] = useState(1);

    const setCurrentPageNumber = (pageNumber) => {
        setCurrentPage(pageNumber);
        props.setCurrentPage(pageNumber);
    }

    useEffect(() => {
        //alert(props.recordsPerPage);
        setCurrentPage(props.currentPage);
        const totalRecords = props.totalRecords;
        const recordsPerPage = props.recordsPerPage;
        const pagesLimitToDisplay = props.pagesLimitToDisplay;
        var totalPages = Math.floor(totalRecords / recordsPerPage);
        const remainder = totalRecords % recordsPerPage;
        if (remainder > 0) {
            totalPages += 1;
        }
        if (totalPages === 0) {
            totalPages = 1;
        }
        if (props.currentPage > totalPages) {
            setCurrentPageNumber(1);
        }
        setPageCount(totalPages);
        var subtractNumber = Math.floor(pagesLimitToDisplay / 2);
        var pageStartNumber = props.currentPage - subtractNumber;
        if((totalPages - props.currentPage) < subtractNumber){
            pageStartNumber = pageStartNumber - (subtractNumber - (totalPages - props.currentPage));
        }
        if(pageStartNumber < 1){
            pageStartNumber = 1;
        }
        var pageEndNumber = props.currentPage + subtractNumber;
        subtractNumber += (pagesLimitToDisplay % 2);
        if(props.currentPage < subtractNumber){
            pageEndNumber = pageEndNumber + (subtractNumber - props.currentPage);
        }
        if(pageEndNumber > totalPages){
            pageEndNumber = totalPages;
        }
        var pagesToShow = [];
        for (var index = pageStartNumber; index <= pageEndNumber; index++) {
            pagesToShow.push(index);
        }
        setPages(pagesToShow);
    }, [props, currentPage]);

    return (
        <div>
            <ul class="pagination justify-content-center">
                <li class={"page-item" + (currentPage === 1 ? " disabled" : "")}>
                    <span class="page-link" aria-label="Next" onClick={() => setCurrentPageNumber(1)} style={{ cursor: 'pointer' }}>
                        <span aria-hidden="true">&#8676;</span>
                    </span>
                </li>
                <li class={"page-item" + (currentPage === 1 ? " disabled" : "")}>
                    <span class="page-link" aria-label="Previous" onClick={() => setCurrentPageNumber(currentPage - 1)} style={{ cursor: 'pointer' }}>
                        <span aria-hidden="true">&laquo;</span>
                    </span>
                </li>
                {
                    pages.map((pageNumber, index) => {
                        return <li class={"page-item" + (currentPage === pageNumber ? " active" : "")}><span class="page-link" onClick={() => setCurrentPageNumber(pageNumber)} style={{ cursor: 'pointer' }}>{pageNumber}</span></li>
                    })
                }
                <li class={"page-item" + (currentPage === pageCount ? " disabled" : "")}>
                    <span class="page-link" aria-label="Next" onClick={() => setCurrentPageNumber(currentPage + 1)} style={{ cursor: 'pointer' }}>
                        <span aria-hidden="true">&raquo;</span>
                    </span>
                </li>
                <li class={"page-item" + (currentPage === pageCount ? " disabled" : "")}>
                    <span class="page-link" aria-label="Next" onClick={() => setCurrentPageNumber(pageCount)} style={{ cursor: 'pointer' }}>
                        <span aria-hidden="true">&#8677;</span>
                    </span>
                </li>
            </ul>
        </div>
    )
}
