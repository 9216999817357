import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

export default function InvestorDropdownComponent(props) {
    const investors = useSelector((state) => state.financeInvestors);
    const [investor, setInvestor] = useState("0");

    const onInvestorChange = (value) => {
        if(props.useLocalStorage) {
            localStorage.setItem("selectedInvestor", value);
        }
        setInvestor(value);
        props.setInvestor(value);
    }

    useEffect(() => {
        if(props.useLocalStorage) {
            const selectedInvestor = (localStorage.getItem("selectedInvestor") ? localStorage.getItem("selectedInvestor") : "0");
            //alert(selectedInvestor);
            setInvestor(selectedInvestor);
            props.setInvestor(selectedInvestor);
        } else {
            setInvestor(props.investor);
        }
    }, [props]);

    useEffect(() => {
        if(props.useLocalStorage) {
            const selectedInvestor = (localStorage.getItem("selectedInvestor") ? localStorage.getItem("selectedInvestor") : "0");
            //alert(selectedInvestor);
            setInvestor(selectedInvestor);
            props.setInvestor(selectedInvestor);
        }
    });
    
    return (
        <div>
            <select className='form-select' value={investor} onChange={event => onInvestorChange(event.target.value)}>
                <option value={"0"}>Choose Investor</option>
                {investors.map((investorObj, index) => {
                    return (
                        <option value={investorObj.id}>{investorObj.first_name + (investorObj.last_name !== "" ? " " + investorObj.last_name : "")}</option>
                    )
                })}
            </select>
        </div>
    )
}
